<template>
  <v-main
    class="mb-12"
    style="background: var(--sub-bg-color); max-width: 500px"
  >
    <v-card style="max-height: 130vw; border-radius: 0px" elevation="0">
      <div
        v-if="!cast"
        style="display: flex; flex-direction: column; min-height: 67vw"
      >
        <!-- UI Component: LoadingImage -->
        <loading-image />
      </div>
      <div v-else>
        <v-img
          max-height="60vh"
          :src="cast_image_url"
          v-on:error="cast_image_url = profile_image"
          contain
          style="position: relative"
        >
          <!-- ランキングタグ -->
          <!-- <RankingTags
            v-if="cast.ranking_tags.length"
            style="position:absolute;left:0px;"
            :style="getRankingTagsStyleObj()"
            :rankingTags="cast.ranking_tags"
          /> -->
          <v-row
            align="end"
            id="prof-photo__item"
            class="prof-photo__item ma-0"
          >
            <v-col cols="7" class="prof-photo__text pr-0">
              <div v-if="cast.entry_date_within_90_days" style="width: 100%">
                <span
                  style="
                    font-size: var(--read-font-size-xs);
                    padding: 0 3px;
                    color: #000;
                    background-color: #fff;
                    border-radius: 3px;
                  "
                  >{{ $t('new') }}</span
                >&nbsp;&nbsp;&nbsp;
                <span style="font-size: var(--read-font-size-s)">{{
                  debutDate(cast.entry_date_within_90_days)
                }}</span>
              </div>
              <!-- <div
                v-if="
                  !cast.entry_date_within_90_days &&
                    cast.position_type === 0 &&
                    cast.debut_date &&
                    debutDateWithin90DaysOr14DaysLater(cast.debut_date)
                "
                style="width: 100%"
              >
                <span
                  style="
                    font-size: var(--read-font-size-xs);
                    padding: 0 3px;
                    color: #000;
                    background-color: #fff;
                    border-radius: 3px;
                  "
                  >{{ $t('new') }}</span
                >&nbsp;&nbsp;&nbsp;
                <span style="font-size: var(--read-font-size-s)">{{
                  debutDate(cast.debut_date)
                }}</span>
              </div>
              <div
                class="d-flex aling-center"
                style="width: 100%; height: 20px; letter-spacing: 0.5px"
                v-if="cast.cast_schedules_count <= 10"
              >
                <IconBeginner class="mr-1" style="fill: #fff; width: 12px" />
                <span style="padding-top: 4px"
                  >{{ $t('attendance_count')
                  }}{{ cast.cast_schedules_count }}</span
                >
              </div> -->
              <div class="mt-1">
                {{ cast.site_cast_name }}&nbsp;&nbsp;&nbsp;{{ $t('age') }}
                {{ cast.age }}<br />
                T{{ cast.height }}&nbsp;&nbsp;&nbsp;{{ cast.bust }}({{
                  cupList[cast.cup]
                }})-{{ cast.waist }}-{{ cast.hip }}
              </div>
              <!-- <div style="margin: 2px 0 2px 0">
                <span
                  v-if="cast.shop.business_type"
                  style="
                    padding: 1px 6px;
                    color: #fff;
                    font-size: var(--read-font-size-xs);
                    border-radius: 3px;
                  "
                  :style="{
                    backgroundColor:
                      cast.shop.business_type.id === 1
                        ? '#54d1f0'
                        : cast.shop.business_type.id === 2
                        ? '#E4C76A'
                        : cast.shop.business_type.id === 3
                        ? '#ff96b7'
                        : '#ccc',
                  }"
                >
                  {{ $t(`business_types.${cast.shop.business_type_id}.name`) }}
                </span>
              </div> -->
              <!-- <div
                style="font-size: var(--read-font-size-xs); line-height: 16px"
              >
                {{ cast.shop.name }}
              </div> -->
              <div
                style="font-size: var(--read-font-size-xs); line-height: 16px"
              >
                {{ cast.shop.min_course_minute
                }}{{ $t('min') }}&nbsp;&nbsp;&yen;{{
                  cast.shop.min_course_money | addComma
                }}～
              </div>
            </v-col>
            <v-col v-if="siteMemberSiteService" cols="5" class="pl-0">
              <!-- URLコピーボタン -->
              <CastUrlCopyBtn style="position:absolute;right:15px;bottom:74px;" />
              <!-- チャット開始ボタン -->
              <!-- <v-btn
                text
                icon
                large
                class="prof-photo__action-btn"
                v-if="
                  cast.castSiteMemberChatRoom &&
                    cast.castSiteMemberChatRoom.id == this.$route.params.id &&
                    this.$route.path.match('/chat/castRoom/')
                "
                v-on:click="closeCastDialog()"
              >
                <IconTalk style="fill: var(--main-color)" />
              </v-btn>
              <v-btn
                text
                icon
                large
                class="prof-photo__action-btn"
                v-else-if="cast.castSiteMemberChatRoom"
                :to="`/chat/castRoom/${cast.castSiteMemberChatRoom.id}`"
              >
                <IconTalk style="fill: var(--main-color)" />
              </v-btn>
              <v-btn
                text
                icon
                large
                class="prof-photo__action-btn"
                v-else-if="
                  cast.cast_site_member_chat_room_open_flag == 1 &&
                    fav === true &&
                    !cast.castSiteMemberChatRoom &&
                    !cast.chatNgFlag &&
                    cast.chatAvailableFlag
                "
                v-on:click="toCastChatRoom(cast.id)"
              >
                <loading-image v-if="toCastChatRoomLoading" />
                <IconTalk v-else style="fill: var(--main-color)" />
              </v-btn>
              <v-btn
                text
                icon
                large
                class="prof-photo__action-btn"
                v-else-if="
                  cast.cast_site_member_chat_room_open_flag == 1 &&
                    fav === false &&
                    !cast.castSiteMemberChatRoom &&
                    !cast.chatNgFlag &&
                    cast.chatAvailableFlag
                "
                v-on:click="chatFavAttentionDialog = true"
              >
                <IconTalk style="fill: var(--main-color)" />
              </v-btn>
              <v-btn
                text
                icon
                large
                class="prof-photo__action-btn"
                v-else-if="
                  cast.cast_site_member_chat_room_open_flag == 1 &&
                    !cast.chatNgFlag &&
                    !cast.chatAvailableFlag
                "
                v-on:click="checkChatAttentionDialog()"
              >
                <IconTalk style="fill: #9e9e9e" />
              </v-btn>
              <v-btn
                text
                icon
                large
                class="prof-photo__action-btn"
                v-else-if="cast.cast_site_member_chat_room_open_flag == 0"
                v-on:click="chatOpenAttentionDialog = true"
              >
                <IconChatNg style="width: 54px" />
              </v-btn> -->
              <!-- お気に入りボタン -->
              <div class="cast-like-btn-container">
                <CastLikeButton2
                  :castId="cast.id"
                  :isFavorite="
                    this.$store.state.siteMemberData.followCastIds.includes(
                      cast.id
                    )
                      ? true
                      : false
                  "
                  :siteMemberFollowCount="cast.site_member_follow_casts_count"
                  @emitFav="emitFav"
                />
              </div>
            </v-col>
          </v-row>
          <v-img
            v-if="this.$store.state.bindCast.cast.thumbnailUrl !== ''"
            :src="cast_image_url"
            max-height="130vw"
            style="z-index: -999; filter: blur(5px)"
          ></v-img>
        </v-img>
      </div>
    </v-card>
    <div
      v-if="cast.images && Object.keys(cast.images).length > 1"
      style="
        background-color: #fff;
        border-radius: 0px;
        border-bottom: 1px solid #eee;
      "
    >
      <v-container class="mx-auto" style="max-width: 350px">
        <v-layout wrap class="justify-space-between">
          <div v-for="(image, key) in cast.images" :key="`image-${key}`">
            <label>
              <v-row class="ma-0">
                <input
                  type="radio"
                  name="image_list_radio"
                  :value="key"
                  v-model="selectImageId"
                  style="display: none"
                />
                <v-avatar
                  height="50"
                  width="50"
                  :style="{
                    border:
                      key == selectImageId
                        ? '1.8px solid var(--main-color)'
                        : '1.8px solid transparent',
                  }"
                  style="cursor: pointer"
                >
                  <v-img :src="image" max-height="100%" max-width="100%" />
                </v-avatar>
              </v-row>
            </label>
          </div>
        </v-layout>
      </v-container>
    </div>
    <v-card
      v-if="
        cast.site_member_site_reservations_available_flag == 0 ||
        this.isDummy === true
      "
      class="text-center pt-2"
      elevation="0"
    >
      <span
        class=""
        style="
          color: var(--main-color);
          font-size: var(--read-font-size-m);
          font-weight: bold;
        "
      >
        {{ $t('text_a9x3') }}
      </span>
      <!-- <div class="text-center">
        <v-btn class="my-3" color="primary" outlined>
          <a
            :href="cast.reserveUrl"
            style="text-decoration: none; font-weight: bold"
            >オフィシャルホームページへ</a
          >
        </v-btn>
      </div> -->
    </v-card>
    <!-- 出勤スケジュール -->
    <v-card
      v-if="cast"
      class="mb-8 prof-schedule-mb"
      style="border-radius: 0px"
      elevation="0"
    >
      <v-card-title
        class="mb-3"
        style="padding-bottom: 16px; border-bottom: 1px solid #e6e5e5"
      >
        <h2 class="content-box-header">
          <span>{{ $t('work_schedule') }}</span>
        </h2>
      </v-card-title>
      <!-- ↓castScheduleが空かどうかBooleanをpropsとして渡す -->
      <SelectedAddress
        :is-loading-schedule="isLoadingSchedule"
        :shop-maps="shopMaps"
        @reloadSchedule="getCastSchedule"
        v-if="isOnceLoadCastSchedule"
      />
      <v-slide-x-reverse-transition>
        <ReserveSchedule
          :castInfo="castInfo"
          :freeTimeFrames="freeTimeFrames"
          :availableAreas="availableAreas"
          :scheduleInfo="scheduleInfo"
          :reservationWaitlist="reservationWaitlist"
          :reserveUrl="cast.reserveUrl"
          :castScheduleLoading="castScheduleLoading"
          :shopAreaIds="shopAreaIds"
          :ngAreaFlag="ngAreaFlag"
          :ngReservableFlag="ngReservableFlag"
          :defaultAddressNameArr="defaultAddressNameArr"
          :height="600"
          @reloadSchedule="getCastSchedule"
          class="pa-4"
        />
      </v-slide-x-reverse-transition>
    </v-card>
        <!-- PhotoDiary Slider -->
        <v-card class="mb-5" style="border-radius: 0px" elevation="0">
      <v-card-title>
        <h2 class="content-box-header" style="width: 50%">
          <span>{{ $t('photo_diary') }}</span>
        </h2>
        <h2
          class="pr-2 text-right"
          style="width: 50%; font-size: var(--read-font-size-m)"
        >
          <!-- <span>
            <v-icon class="pb-1" color="primary" style="font-size: 1.5rem"
              >mdi-heart</v-icon
            >{{ cast.photoDiaryArticleLikeCount }}
          </span> -->
          <span class="ml-2">
            {{ cast.photoDiaryArticleCount }}{{ $t('diaries') }}
          </span>
        </h2>
      </v-card-title>
      <v-card-text
        class="prof-self-basic-info__item mx-auto"
        style="max-width: 500px"
      >
        <v-row class="pa-4">
          <v-col
            class="d-flex child-flex"
            style="padding: 0.5px"
            cols="4"
            v-for="(article, index) in photoDiaryArticles"
            :key="`photoDiaryArticle-${index}`"
          >
            <v-card
              flat
              tile
              class="d-flex prof-photo-diary__wrapper"
              elevation="0"
              :ripple="false"
              v-on:click="openArticle(`photoId-${article.id}`)"
            >
              <v-img
                aspect-ratio="1"
                :src="article.attachment_image_url || article_image"
              />
              <div :class="`scope ${getScopeClass(article.scope)}`">
                {{ $t('limited_publication') }}
                <span>{{ getScopeLabel(article.scope) }}</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-2 text-center">
          <v-btn
            v-if="
              !photoDiaryArticleLoading &&
              !this.getPhotoDiaryArticleCompleteFlag
            "
            class="prof-photo-diary__list-btn"
            color="#FFE9EE"
            elevation="0"
            v-on:click="getPhotoDiaryArticle()"
            style="
              border-radius: 21px;
              color: #ff7d9a;
              width: 155px;
              font-size: 13px;
            "
          >
            {{ $t('more') }}
          </v-btn>
          <!-- UI Component: LoadingImage -->
          <loading-image v-if="photoDiaryArticleLoading" />
        </div>
      </v-card-text>
    </v-card>
        <!-- クチコミ -->
    <v-card class="mb-5" style="border-radius: 0px"
      v-if="cast" elevation="0"
      >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data" v-on:click="getCastReviews(10)">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('text_dY6U') }}</span>
            </h2>
            <h2
              class="pr-2 text-right"
              style="width: 100%; font-size: var(--read-font-size-m)"
            >
              <span>{{ totalCount }} {{ $t('text_A7dy') }}</span>
            </h2>
          </template>
          <!-- クチコミ一覧表示 -->
          <div v-if="castReviewLoading">
            <loading-image />
          </div>
          <div
            v-if="
              !castReviewLoading &&
              !displayCastReviews.length
            "
            class="px-3"
            style="font-size: 14px; font-weight: bold"
          >
            {{ $t('text_Lx6Q') }}
          </div>
          <transition-group v-else name="review_list">
            <div
              v-for="(item, index) in displayCastReviews"
              :key="`displayCastReviews-${index}`"
            >
              <v-list-item :ripple="false" class="ma-0 pa-0">
                <Review
                  :cast-review-data="item"
                  :cast-url-hash="cast.url_hash"
                />
              </v-list-item>
            </div>
          </transition-group>
          <!-- もっと見るボタン -->
          <div v-if="showSeeMoreReviewBtn" class="text-center mt-3">
            <!-- v-btnだと押下時の背景色が残ってしまうのでボタン自作 -->
            <v-btn
              class="prof-photo-diary__list-btn"
              color="#FFE9EE"
              elevation="0"
              v-on:click="seeMoreCastReviews()"
              style="
                border-radius: 21px;
                color: #ff7d9a;
                width: 155px;
                font-size: 13px;
              "
            >
              {{ $t('text_Cz2d') }}
            </v-btn>
          </div>
        </v-list-group>
      </v-list>
    </v-card>
    <!-- 基本情報 -->
    <v-card
      class="mb-5"
      v-if="cast && castProfiles"
      style="border-radius: 0px"
      elevation="0"
    >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('basic_information') }}</span>
            </h2>
          </template>
          <v-list-item class="pa-0 pb-3" style="background-color: #fff">
            <v-list-item-content
              class="service_table"
              style="display: block; padding: 8px 16px"
            >
              <v-simple-table dense>
                <tr
                  v-for="(profile, index) in castProfiles"
                  :key="`castProfiles-${index}`"
                >
                  <td class="pr-0">
                    {{ profile.label }}
                  </td>
                  <td style="font-weight: bold">
                    {{ profile.value }}
                  </td>
                </tr>
              </v-simple-table>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
        <!-- プレイデータ -->
        <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-if="profilePlayCategories"
      elevation="0"
    >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('play_data') }}</span>
            </h2>
          </template>
          <v-list-item
            class="pa-0"
            style="background-color: var(--sub-bg-color)"
          >
            <v-list-item-content class="pa-0">
              <v-card-text v-if="castProfilePlays">
                <div
                  v-for="(profilePlays, i) in profilePlayCategories"
                  :key="`profilePlayCategories-${i}`"
                  class="my-2"
                >
                  <div
                    v-if="
                      profilePlays.profile_plays[0] &&
                      profilePlays.profile_plays[0]['id'] != '' &&
                      profilePlays.name != 'PLTN_PLAY'
                    "
                    class="mb-6"
                  >
                    <div class="pl-1 prof-play-info__header">
                      {{ profilePlays.name }}
                    </div>
                    <dl
                      v-for="(play, n) in profilePlays.profile_plays"
                      :key="`profilePlays-${n}`"
                      class="prof-play-info__box"
                    >
                      <dt>
                        <span>{{ play.name }}</span>
                      </dt>
                      <dd>
                        <span
                          v-if="castProfilePlays[play.id]"
                          :style="{
                            color:
                              castProfilePlays[play.id] == 1
                                ? 'var(--main-color)'
                                : '',
                          }"
                          >{{ playShape[castProfilePlays[play.id]] }}</span
                        >
                        <span v-else>―</span>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div v-if="serviceCourses.length > 0">
                  <div class="mb-6">
                    <div class="pl-1 prof-play-info__header">
                      {{ $t('courses') }}
                    </div>
                    <dl
                      v-for="(course, n) in serviceCourses"
                      :key="`serviceCourses-${n}`"
                      class="prof-play-info__box"
                    >
                      <dt>
                        <span v-html="course.offical_site_display_name"></span>
                      </dt>
                      <dd>
                        <span
                          v-if="
                            !cast.ng_courses.split(',').includes(course.name)
                          "
                          style="color: var(--main-color)"
                          >{{ playShape['1'] }}</span
                        >
                        <span v-else style="color: ''">{{
                          playShape['3']
                        }}</span>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div v-if="serviceOptions.length > 0">
                  <div class="mb-6">
                    <div class="pl-1 prof-play-info__header">
                      {{ $t('options') }}
                    </div>
                    <dl
                      v-for="(option, n) in serviceOptions"
                      :key="`serviceOptions-${n}`"
                      class="prof-play-info__box"
                    >
                      <dt>
                        <span>{{ option.offical_site_display_name }}</span>
                      </dt>
                      <dd>
                        <span
                          v-if="
                            !cast.ng_options.split(',').includes(option.name)
                          "
                          style="color: var(--main-color)"
                          >{{ playShape['1'] }}</span
                        >
                        <span v-else style="color: ''">{{
                          playShape['3']
                        }}</span>
                      </dd>
                    </dl>
                  </div>
                </div>
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <!-- おすすめポイント -->
    <v-card class="mb-5" v-if="recommendPointTags.length > 0" elevation="0">
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('recommended_points') }}</span>
              <IconQuestion
                v-if="!newcomer"
                @click.stop="recommendPointTagsDialog = true"
                class="mx-1"
                style="height: auto"
              />
            </h2>
          </template>
          <v-list-item class="pa-0" style="background-color: #fff">
            <v-list-item-content style="display: block; padding: 8px 16px">
              <p class="caption" style="color: var(--main-color)">
                {{ recommendPointText }}
              </p>
              <v-chip
                v-for="cast_tag in recommendPointTags"
                class="prof-tag"
                :key="`recommendPointTags-${cast_tag.id}`"
                :color="recommendPointTagColor(cast_tag).color"
                :text-color="recommendPointTagColor(cast_tag).textColor"
                :outlined="recommendPointTagColor(cast_tag).outlined"
                :class="{
                  'prof-tag-guide_border-gray': recommendPointTagColor(cast_tag)
                    .outlined,
                }"
                >{{ $t('cast_tags.' + cast_tag.id + '.name') }}</v-chip
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <!-- Q&A -->
    <v-card class="mb-5" style="border-radius: 0px" v-if="cast" elevation="0">
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('q_a') }}</span>
            </h2>
            <h2
              class="pr-2 text-right"
              style="width: 100%; font-size: var(--read-font-size-m)"
            >
              <span
                >{{
                  cast.cast_free_contents_media
                    ? cast.cast_free_contents_media.length
                    : 0
                }}{{ $t('q_a') }}</span
              >
            </h2>
          </template>
          <v-list-item class="pa-0 pb-3" style="background-color: #fff">
            <v-list-item-content
              class="service_table"
              style="display: block; padding: 8px 16px"
            >
              <v-simple-table dense>
                <tr
                  v-for="(item, index) in cast.cast_free_contents_media"
                  :key="index"
                >
                  <td class="pr-0">
                    {{ item.title }}
                  </td>
                  <td style="font-weight: bold">
                    {{ item.content }}
                  </td>
                </tr>
              </v-simple-table>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
        <!-- スタッフコメント -->
        <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-if="cast.staff_comment"
      elevation="0"
    >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('staff_comment') }}</span>
            </h2>
          </template>
          <v-list-item
            class="pa-0"
            style="background-color: #fff; margin-bottom: 40px"
          >
            <v-list-item-content class="pa-0">
              <div
                class="pa-4"
                v-html="unescapeHtml(cast.staff_comment)"
                style="
                  color: var(--read-font-color-primary);
                  font-size: var(--read-font-size-s);
                  font-weight: bold;
                  word-wrap: break-word;
                "
              ></div>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <!-- 予約履歴 -->
    <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-if="isGuest"
      elevation="0"
    >
      <v-list :class="`pa-0 list-reservations`" disabled>
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('reservation_history') }}</span>
            </h2>
            <h2
              class="pr-2 text-right"
              style="width: 100%; font-size: var(--read-font-size-m)"
            >
              <span>0{{ $t('reservations') }}</span>
            </h2>
          </template>
        </v-list-group>
      </v-list>
    </v-card>
    <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-else-if="Object.keys(reservations).length > 0"
      elevation="0"
    >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('reservation_history') }}</span>
            </h2>
            <h2
              class="pr-2 text-right"
              style="width: 100%; font-size: var(--read-font-size-m)"
            >
              <span
                >{{ reservations.allCountReservaions
                }}{{ $t('reservations') }}</span
              >
            </h2>
          </template>
          <v-list-item
            class="pa-0 pb-3"
            style="background-color: #fff; margin-bottom: 40px"
          >
            <v-list-item-content class="pa-0 service_table">
              <v-simple-table fixed-header dense class="pl-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="pa-2 text-left">{{ $t('date_and_time') }}</th>
                      <th class="pa-2 text-left">{{ $t('course') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in reservations.data"
                      :key="`reservations-${index}`"
                    >
                      <td class="pa-2">
                        <div
                          v-if="
                            yearCheck(
                              item.start_datetime,
                              reservations.data[index - 1]
                            )
                          "
                        >
                          <div
                            class="cast-prof-reserve-list__year"
                            style="color: #fff"
                          >
                            {{ item.start_datetime | year }}
                          </div>
                        </div>
                        <nuxt-link
                          :to="`/reserve/${item.id}`"
                          style="
                            text-decoration: none;
                            color: var(--read-font-color-primary);
                          "
                        >
                          {{ item.start_datetime | moment }}~
                        </nuxt-link>
                      </td>
                      <td class="pa-2">
                        <nuxt-link
                          :to="`/reserve/${item.id}`"
                          style="
                            text-decoration: none;
                            color: var(--read-font-color-primary);
                          "
                        >
                          <ul class="pl-0" style="list-style: none">
                            <li
                              class="cast-prof-reserve-list__course"
                              style="color: #bdbdbd; position: relative"
                            >
                              <span v-html="dispayCourseName(item)"></span>
                              <v-icon
                                class="ml-3"
                                style="
                                  position: absolute;
                                  right: 6px;
                                  top: 50%;
                                  transform: translateY(-50%);
                                "
                                >mdi-chevron-right</v-icon
                              >
                            </li>
                          </ul>
                        </nuxt-link>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <!-- プライベートメモ -->
    <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-if="isGuest"
      elevation="0"
    >
      <v-list :class="`pa-0 list-freememo`" disabled>
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('private_memo') }}</span>
            </h2>
            <h2
              class="pr-2 text-right"
              style="width: 100%; font-size: var(--read-font-size-m)"
            >
              <span>0{{ $t('memos') }}</span>
            </h2>
          </template>
        </v-list-group>
      </v-list>
    </v-card>
    <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-else-if="castFreeMemoLoading"
      elevation="0"
    >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('private_memo') }}</span>
            </h2>
            <h2
              class="pr-2 text-right"
              style="width: 100%; font-size: var(--read-font-size-m)"
            >
              <span>{{ castFreeMemos.length }}{{ $t('memos') }}</span>
            </h2>
          </template>
          <v-list-item
            class="pa-0"
            style="background-color: #fff; margin-bottom: 40px"
          >
            <v-list-item-content class="pa-0">
              <div
                class="pa-0 mt-3 mb-3"
                style="max-height: 320px; overflow: auto"
              >
                <div
                  class="ma-0"
                  v-for="(data, index) in castFreeMemos"
                  :key="`castFreeMemos-${index}`"
                >
                  <div class="pl-5 pr-5 pt-3 pb-3 free_memo_area">
                    {{ data.memo }}
                  </div>
                  <div
                    class="pl-5"
                    style="
                      font-size: 12px;
                      font-weight: bold;
                      color: var(--read-font-color-primary);
                    "
                  >
                    {{ $t('last_update') }}：{{
                      data.created_at | memoCreatedTime
                    }}
                  </div>
                </div>
              </div>
              <div class="pa-1 text-center mb-3">
                <v-btn
                  small
                  color="#FFE9EE"
                  class="px-0 cast-prof__header-edit-button"
                  v-on:click="clickEditFreeMemoButton"
                >
                  <span>{{ $t('edit') }}</span>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
        <!-- お店の詳細 -->
        <v-card
      class="mb-5"
      style="border-radius: 0px"
      v-if="siteMemberSiteService"
      elevation="0"
    >
      <v-list class="pa-0">
        <v-list-group class="prof-list-data" :value="true">
          <template v-slot:activator>
            <h2 class="content-box-header" style="width: 100%">
              <span>{{ $t('services') }}</span>
            </h2>
          </template>
          <v-list-item class="pa-0" style="background-color: #fff">
            <v-list-item-content class="pa-0">
              <v-card-text class="pt-0 py-4">
                <!-- <div class="my-2 px-4">
                  <span
                    class="pl-1"
                    style="
                      color: var(--color-1);
                      font-size: var(--read-font-size-m);
                      font-weight: bold;
                    "
                  >
                    {{ cast.shop.name }}
                    <a
                      :href="cast.shop.offical_site_pc_url"
                      style="text-decoration: none"
                      target="_blank"
                    >
                      <v-icon
                        class="pl-2"
                        style="
                          color: #000;
                          font-size: var(--read-font-size-m);
                          font-weight: bold;
                        "
                        >mdi-open-in-new</v-icon
                      >
                    </a>
                  </span>
                </div> -->
                <loading-image v-if="castScheduleLoading" />
                <div v-show="!castScheduleLoading && cast.shop.concept" class="my-2 px-4 shop-concept">
                  {{ cast.shop.concept }}
                </div>
                <div
                  v-if="Object.keys(siteMemberSiteServiceData).length > 0"
                  class="my-2 px-4 service_table"
                >
                  <v-simple-table
                    v-for="(serviceDatas, i) in siteMemberSiteServiceData"
                    :key="`siteMemberSiteServiceData-${i}`"
                    dense
                    class="my-2"
                  >
                    <tr>
                      <td
                        v-if="serviceDatas['set']['display_flag'] === 0"
                        class="text-center charge-td"
                        colspan="2"
                        style="
                          background: var(--color-1);
                          color: #fff;
                          font-weight: bold;
                        "
                      >
                        {{ $t('charge') }}
                      </td>
                      <td
                        v-else
                        class="text-center charge-td"
                        colspan="2"
                        style="
                          background: var(--color-1);
                          color: #fff;
                          font-weight: bold;
                        "
                      >
                        {{ $t('charge') }}（{{ serviceDatas['set']['name'] }}）
                      </td>
                    </tr>
                    <tr
                      v-for="(service, i) in serviceDatas['services']"
                      :key="`serviceDatas-${i}`"
                    >
                      <td>{{ service.name }}</td>
                      <td style="font-weight: bold">
                        <div v-html="serviceData(service.service_money)" />
                      </td>
                    </tr>
                  </v-simple-table>
                </div>
                <div class="my-8 px-4 service_table">
                  <v-simple-table
                    v-if="allServiceContentTagsTrue"
                    dense
                    class="my-2"
                  >
                    <tr>
                      <td
                        class="pr-0"
                        style="border-top: 1px solid #e8e8e8 !important"
                      >
                        <div class="d-flex align-center">
                          <IconYen
                            class="mr-2"
                            style="
                              fill: var(--color-3);
                              font-size: var(--read-font-size-l);
                            "
                          />{{ $t('average_budget') }}
                        </div>
                      </td>
                      <td style="border-top: 1px solid #e8e8e8 !important">
                        &yen;{{ cast.shop.average_usage_money | addComma }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-0">
                        <div class="d-flex align-center">
                          <IconClothes
                            class="mr-2"
                            style="
                              fill: var(--color-3);
                              font-size: var(--read-font-size-l);
                            "
                          />{{ $t('undressing') }}
                        </div>
                      </td>
                      <td>
                        {{
                          $t(
                            cast.take_off[cast.shop.service_content_tag1][
                              'label'
                            ]
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-0">
                        <div class="d-flex align-center">
                          <IconKiss
                            class="mr-2"
                            style="
                              fill: var(--color-3);
                              font-size: var(--read-font-size-l);
                            "
                          />{{ $t('kiss') }}
                        </div>
                      </td>
                      <td>
                        {{
                          $t(cast.kiss[cast.shop.service_content_tag2]['label'])
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-0">
                        <div class="d-flex align-center">
                          <IconTouch
                            class="mr-2"
                            style="
                              fill: var(--color-3);
                              font-size: var(--read-font-size-l);
                            "
                          />{{ $t('touch') }}
                        </div>
                      </td>
                      <td>
                        {{
                          $t(
                            cast.touch[cast.shop.service_content_tag3]['label']
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-0">
                        <div class="d-flex align-center">
                          <IconFinish
                            class="mr-2"
                            style="
                              fill: var(--color-3);
                              font-size: var(--read-font-size-l);
                            "
                          />{{ $t('finish') }}
                        </div>
                      </td>
                      <td>
                        {{
                          $t(
                            cast.finish[cast.shop.service_content_tag4]['label']
                          )
                        }}
                      </td>
                    </tr>
                  </v-simple-table>
                </div>
                <div
                  class="my-2 px-4 mb-8"
                  style="
                    font-size: var(--read-font-size-s);
                    color: var(--color-2);
                    font-weight: bold;
                  "
                >
                  {{ $t('text_eap9') }}
                </div>
                <!-- 派遣可能エリア -->
                <DispatchableAreas
                  v-if="cast.dispatchable_areas"
                  class="mb-3 px-4"
                  :dispatchableAreas="cast.dispatchable_areas"
                  :dispatchAreaDescription="cast.shop.dispatch_area_description"
                />
                <!-- ポイント還元 -->
                <!-- <div class="px-4" v-if="cast.point_setting.length">
                  <SiteMemberPointSettingSet
                    :pointSettings="cast.point_setting"
                  />
                </div> -->
                <!-- <div class="mt-6 mb-4 px-4" style="position: relative">
                  <v-btn
                    v-if="!toShopChatRoomLoading"
                    v-on:click="_toShopChatRoom(cast.shop.id)"
                    color="#5d5d5d"
                    large
                    block
                    style="
                      color: #fff;
                      font-weight: bold;
                      border-radius: 30px;
                      background: var(--color-1);
                    "
                  >
                    <ul class="pa-0 text-center" style="list-style: none">
                      <li style="font-size: var(--read-font-size-xs)">
                        店舗にチャットでお問い合わせ
                      </li>
                      <li style="font-size: var(--read-font-size-m)">
                        チャットルームを開く
                      </li>
                    </ul>
                  </v-btn>
                  <v-btn
                    v-else
                    color="#5d5d5d"
                    large
                    block
                    style="
                      color: #fff;
                      font-weight: bold;
                      border-radius: 30px;
                      background: var(--color-1);
                    "
                  >
                    <loading-image />
                  </v-btn>
                  <IconTalk
                    style="
                      position: absolute;
                      top: 50%;
                      left: 16%;
                      transform: translateY(-50%);
                      fill: #fff;
                    "
                  />
                </div> -->
                <div
                  class="px-3 py-3 mt-2"
                  style="
                    text-align: center;
                    background-color: var(--main-bg-color);
                    border-radius: 10px;
                  "
                >
                  <p class="font-weight-bold" style="font-size: var(--read-font-size-l);">
                    {{ $t('contact_us') }}
                  </p>
                  <p>
                    <div
                      class="my-2 px-4 mb-5"
                      style="
                        font-size: var(--read-font-size-m);
                      "
                    >
                      {{ $t('teo') }}<br />
                      {{ $t('available_hours') }}
                    </div>
                  </p>
                  <div class="mt-6">
                    <v-btn
                      v-if="$store.state.auth.loggedIn === true"
                      class="btn-chat"
                      style="margin-bottom: 20px;"
                      aria-label="Chat on OTOME"
                      :to="`/${$i18n.locale}/chat/operationRoom`"
                    >
                      <IconTalk
                        style="
                          position: absolute;
                          top: 50%;
                          left: 20%;
                          transform: translateY(-50%);
                          fill: #fff;
                        "
                      />Chat on OTOME
                    </v-btn>
                    <v-btn
                      v-else
                      v-on:click="isGuestDialog($t('login_text_3'))"
                        class="btn-chat"
                        aria-label="Chat on OTOME"
                        style="margin-bottom: 20px;"
                    >
                      <IconTalk
                        style="
                          position: absolute;
                          top: 50%;
                          left: 20%;
                          transform: translateY(-50%);
                          fill: #fff;
                        "
                      />Chat on OTOME
                    </v-btn>
                    <v-divider />
                    <p>
                      <div
                        class="my-2 px-4 mb-5 font-weight-bold"
                        style="
                          font-size: var(--read-font-size-s);
                          color: var(--sub-color);
                          line-height: 1.6;
                        "
                      >
                        {{ $t('chat_text_1') }}<br />
                      </div>
                    </p>
                  </div>
                  <div class="mt-6">
                    <v-btn
                      class="btn-whats-app"
                      aria-label="Chat on WhatsApp"
                      :href="whatsAppUrl"
                    >
                      <v-icon size="3vh" class="pr-1" style="color: white"
                        >mdi-whatsapp</v-icon
                      >Chat on WhatsApp
                    </v-btn>
                  </div>
                  <IdCopyArea />
                </div>
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    <!-- 写メ日記ダイアログ -->
    <CastProfilePhotoDiary
      ref="castProfilePhotoDiary"
      :photoDiaryArticles="photoDiaryArticles"
      :hashtagCategories="hashtagCategories"
      :cast="cast"
      :photoDiaryArticleLoading="photoDiaryArticleLoading"
      :getPhotoDiaryArticleCompleteFlag="getPhotoDiaryArticleCompleteFlag"
      :getScopeAnnotation="getScopeAnnotation"
      :urlHash="urlHash"
      :likedNicknames="likedNicknames"
      @articleDialogImageLoad="articleDialogImageLoad()"
      @getPhotoDiaryArticle="getPhotoDiaryArticle()"
      @onBodyHashtagClicked="onBodyHashtagClicked()"
      @getLikedNicknames="getLikedNicknames(photoDiaryArticles)"
    />
    <!-- 予約スケジュールダイアログ -->
    <v-dialog v-model="reserveScheduleDialog" fullscreen>
      <v-card style="border-radius: 0px" elevation="0">
        <v-card-title class="pl-4 pt-2 pb-0">
          <v-icon
            size="1.5rem"
            color="#333"
            v-on:click="reserveScheduleDialog = false"
            >mdi-chevron-left</v-icon
          >
          <span
            class="pl-2"
            style="
              color: var(--main-color);
              font-size: var(--read-font-size-m);
              font-weight: bold;
            "
            ><span v-if="cast">{{ $t('ms') }}{{ cast.site_cast_name }}</span
            >{{ $t('work_schedule') }}</span
          >
        </v-card-title>
        <!-- ↓castScheduleが空かどうかBooleanをpropsとして渡す -->
        <SelectedAddress
          :is-loading-schedule="isLoadingSchedule"
          :shop-maps="shopMaps"
          @reloadSchedule="getCastSchedule"
          v-if="isOnceLoadCastSchedule"
        />
        <span
          class="pl-5"
          style="
            font-size: var(--read-font-size-s);
            color: var(--read-font-color-primary);
          "
          >{{ $t('text_k60d') }}</span
        >
        <ReserveSchedule
          :castInfo="castInfo"
          :freeTimeFrames="freeTimeFrames"
          :availableAreas="availableAreas"
          :scheduleInfo="scheduleInfo"
          :reservationWaitlist="reservationWaitlist"
          :reserveUrl="cast.reserveUrl"
          :castScheduleLoading="castScheduleLoading"
          :shopAreaIds="shopAreaIds"
          :ngAreaFlag="ngAreaFlag"
          :ngReservableFlag="ngReservableFlag"
          :defaultAddressNameArr="defaultAddressNameArr"
          :height="600"
          @reloadSchedule="getCastSchedule"
          class="pa-4"
        />
        <v-card-text v-if="!isOnceLoadCastSchedule">
          <!-- UI Component: LoadingImage -->
          <loading-image />
        </v-card-text>
        <v-container class="text-center">
          <v-btn
            text
            color="primary"
            v-on:click="reserveScheduleDialog = false"
            >{{ $t('close') }}</v-btn
          >
        </v-container>
      </v-card>
    </v-dialog>
    <!-- フリーメモダイアログ -->
    <v-dialog
      v-model="editFreeMemoDialog"
      max-width="500"
      fullscreen
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="mx-auto" style="max-width: 500px">
          <v-list
            subheader
            class="pa-0"
            style="position: sticky; top: 0; z-index: 2"
          >
            <v-list-item class="list_style" style="background-color: #fff">
              <v-row class="ma-0 justify-space-between">
                <v-col cols="5" class="pa-0">
                  <v-btn
                    small
                    text
                    v-on:click="editFreeMemoDialog = false"
                    class="cast-prof-memo-dialog__cancel-button"
                  >
                    <span>{{ $t('cancel') }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="3" class="pa-0 text-right">
                  <v-btn
                    v-on:click="saveEditFreeMemo()"
                    small
                    elevation="0"
                    class="cast-prof-memo-dialog__save-button"
                  >
                    <span style="color: #fff">{{ $t('save') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <div
            class="py-1 text-center"
            style="
              font-size: var(--read-font-size-s);
              font-weight: bold;
              color: var(--main-color);
            "
          >
            {{ $t('text_yoem7') }}{{ $t('mr')
            }}{{ this.$store.state.siteMemberData.nickname }}<br />{{
              $t('text_i9ht')
            }}
          </div>
          <v-row v-if="freeMemoDate" class="ma-0 justify-space-between">
            <v-col
              cols="4"
              class="text-center cast-prof-memo-dialog__date pa-2 pt-5"
              >{{ freeMemoDate | memoCreatedTime }}</v-col
            >
            <v-col cols="8" class="pa-1">
              <v-textarea
                outlined
                auto-grow
                hide-details
                class="edit_memo_area"
                rows="1"
                v-model="newPostCastFreeMemo"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(data, index) in castFreeMemos"
            :key="`castFreeMemosDialog-${index}`"
            class="ma-0 justify-space-between"
          >
            <v-col
              cols="4"
              class="text-center cast-prof-memo-dialog__date pa-2 pt-5"
              >{{ data.created_at | memoCreatedTime }}</v-col
            >
            <v-col cols="8" class="pa-1">
              <v-textarea
                outlined
                auto-grow
                hide-details
                class="edit_memo_area"
                rows="1"
                v-model="postCastFreeMemo[data.id]['memo']"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="chatFavAttentionDialog">
      <v-card class="pa-4" style="font-weight: bold">
        チャットを開始するにはお気に入りに追加してください。
        <br /><br />
        チャットを使って出勤のリクエストが可能です。
        <div class="text-center">
          <v-btn
            v-on:click="chatFavAttentionDialog = false"
            class="mt-6"
            color="primary"
            style="font-weight: bold"
            >戻る</v-btn
          >
        </div>
      </v-card>
    </v-dialog> -->
    <!-- <v-dialog v-model="chatAttentionDialog">
      <v-card
        v-if="this.$store.state.auth.loggedIn === true"
        class="pa-4"
        style="font-weight: bold"
      >
        チャットを開始するにはスターが{{
          parseInt(cast.cast_site_member_chat_room_open_star)
        }}つ以上必要です。 <br /><br />
        引き続きマナーをお守りいただいた上でご利用をお願いいたします。
        <br /><br />
        <div>
          スターとは
          <div class="d-inline-block" style="position: relative">
            <IconQuestion
              v-on:click="
                pointHelpDialog = true;
                chatAttentionDialog = false;
              "
              style="height: auto; position: absolute; top: -14px; left: 2px"
            />
          </div>
        </div>
        <div class="text-center">
          <v-btn
            v-on:click="chatAttentionDialog = false"
            class="mt-6"
            color="primary"
            style="font-weight: bold"
            >戻る</v-btn
          >
        </div>
      </v-card>
    </v-dialog> -->
    <!-- <v-dialog v-model="chatOpenAttentionDialog">
      <v-card class="pa-4" style="font-weight: bold">
        お客様からのチャットを受付していないキャストの為、チャットルームは作成出来ません。
        <div class="text-center">
          <v-btn
            v-on:click="chatOpenAttentionDialog = false"
            class="mt-6"
            color="primary"
            style="font-weight: bold"
            >戻る</v-btn
          >
        </div>
      </v-card>
    </v-dialog> -->
    <!-- 予約ナビゲーション -->
    <div v-if="cast && reserveNavDialog">
      <nav class="cv-action-nav">
        <div
          v-on:click="$router.go(-1)"
          v-ripple
          class="cv-action-nav__btn-default--fav"
          style="cursor: pointer"
        >
          {{ $t('back') }}
        </div>
        <div class="cv-action-nav__btn-default--fav">
          <ReserveButton
            :castId="cast.id"
            :corporationId="cast.corporation_id"
            :castUrl="cast.url_hash"
          />
        </div>
      </nav>
    </div>
    <!-- おすすめポイント解説ダイアログ -->
    <v-overlay :value="recommendPointTagsDialog" @click="recommendPointTagsDialog = false" />
    <v-dialog v-model="recommendPointTagsDialog">
      <v-card class="pa-4" style="font-weight: bold">
        <p style="color: var(--main-color); font-size: 14px">
          {{ $t('text_2v9a') }}
        </p>
        <v-chip
          class="prof-tag-guide"
          :color="castEvaluationTagColors[1].color"
          :text-color="castEvaluationTagColors[1].textColor"
        >
          {{ $t('text_1f9b') }}
        </v-chip>
        <v-chip
          class="prof-tag-guide"
          :color="castEvaluationTagColors[2].color"
          :text-color="castEvaluationTagColors[2].textColor"
        >
          {{ $t('text_1k8a') }}
        </v-chip>
        <v-chip
          class="prof-tag-guide"
          :color="castEvaluationTagColors[3].color"
          :text-color="castEvaluationTagColors[3].textColor"
        >
          {{ $t('text_0o9b') }}
        </v-chip>
        <v-chip
          class="prof-tag-guide prof-tag-guide_border-gray"
          :color="castEvaluationTagColors[4].color"
          :text-color="castEvaluationTagColors[4].textColor"
          outlined
        >
          {{ $t('text_5o9b') }}
        </v-chip>
        <v-chip
          class="prof-tag-guide"
          :color="castEvaluationTagColors[5].color"
          :text-color="castEvaluationTagColors[5].textColor"
        >
          {{ $t('text_5o9c') }}
        </v-chip>
      </v-card>
    </v-dialog>
    <!-- LikedPhotoDiaryLimitDialog -->
    <LikedPhotoDiaryLimitDialog />
    <!-- HelpFullReviewLimitDialog -->
    <HelpFullReviewLimitDialog />
    <!-- HelpFullReviewLimitDateDialog -->
    <HelpFullReviewLimitDateDialog />
    <!-- UI Component: LoadingPost -->
    <loading-save />
    <!-- pointHelpDialog -->
    <!-- <v-dialog
      v-model="pointHelpDialog"
      max-width="500"
      fullscreen
      scrollable
      transition="fade-transition"
    >
      <AboutStarDialog
        @emitStarHelpDialog="pointHelpDialog = !pointHelpDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="guestAlert"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 primary--text text-center">
          {{ $t('login_text_8') }}
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="guestAlert = false"
            style="color: var(--main-color); font-weight: bold"
          >
            {{ $t('close') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog> -->
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
    <!-- [ログアウト時のみ] 新規登録/ログインFixボタン-->
    <SignInButton v-if="$store.state.auth.loggedIn === false" />

    <v-overlay :value="this.$store.state.dialog.helpFullButtonOverlay">
      <loading-image />
    </v-overlay>
  </v-main>
</template>

<script>
import ReserveSchedule from '~/components/pages/cast/ReserveSchedule.vue';
import ReserveButton from '~/components/pages/call/ReserveButton.vue';
import Review from '~/components/pages/cast/Review.vue';
import AreaForm from '~/components/pages/call/AreaForm.vue';
import SelectedAddress from '~/components/pages/cast/SelectedAddress.vue';
import LikedPhotoDiaryLimitDialog from '~/components/pages/dialog/LikedPhotoDiaryLimitDialog.vue';
import HelpFullReviewLimitDialog from '~/components/pages/dialog/HelpFullReviewLimitDialog.vue';
import HelpFullReviewLimitDateDialog from '~/components/pages/dialog/HelpFullReviewLimitDateDialog.vue';
//import AboutStarDialog from '~/components/pages/mypage/AboutStarDialog.vue';
import CastLikeButton2 from '~/components/layouts/CastLikeButton2.vue';
import SiteMemberPointSettingSet from '~/components/pages/cast/SiteMemberPointSettingSet.vue';
import DispatchableAreas from '~/components/pages/cast/DispatchableAreas.vue';
import CastProfilePhotoDiary from '~/components/pages/dialog/CastProfilePhotoDiary.vue';
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';
import SignInButton from '~/components/ui/button/SignInButton.vue';
import CastUrlCopyBtn from '~/components/pages/common/CastUrlCopyBtn.vue';
// import RankingTags from '~/components/pages/common/RankingTags.vue';
import article_image from '~/assets/img/photo_diary_noimage_p.jpg';
import profile_image from '~/assets/img/noimage_p.png';
import profile_ngImage from '~/assets/img/ngimage_p.png';
import IconYen from '~/assets/img/icon-yen.svg';
import IconClothes from '~/assets/img/icon-clothes.svg';
import IconKiss from '~/assets/img/icon-kiss.svg';
import IconTouch from '~/assets/img/icon-touch.svg';
import IconFinish from '~/assets/img/icon-finish.svg';
import IconTalk from '~/assets/img/icon-talk.svg';
import IconTalk2 from '~/assets/img/icon-talk2.svg';
import IconBeginner from '~/assets/img/icon-beginner.svg';
import IconChatNg from '~/assets/img/icon-chat-ng.svg';
import IconCopy from '~/assets/img/icon-copy.svg';
import IdCopyArea from '~/components/pages/common/IdCopyArea.vue';


import { async, resolve } from 'q';
const moment = require('moment');
import _ from 'lodash';

export default {
  auth: false,
  components: {
    ReserveSchedule,
    ReserveButton,
    Review,
    AreaForm,
    SelectedAddress,
    LikedPhotoDiaryLimitDialog,
    HelpFullReviewLimitDialog,
    HelpFullReviewLimitDateDialog,
    //AboutStarDialog,
    CastLikeButton2,
    SiteMemberPointSettingSet,
    DispatchableAreas,
    IconYen,
    IconClothes,
    IconKiss,
    IconTouch,
    IconFinish,
    IconTalk,
    IconTalk2,
    IconBeginner,
    CastProfilePhotoDiary,
    GuestAlertDialog,
    SignInButton,
    IconChatNg,
    CastUrlCopyBtn,
    // RankingTags,
    IconCopy,
    IdCopyArea

  },
  head() {
    let title = ''
    let castName = ''
    let areaName = ''
    let businessTypeName = ''
    if (typeof this.metaItem !== 'undefined' && Object.keys(this.metaItem).length) {
      castName = this.metaItem.cast.site_cast_name
      areaName = this.metaItem.area.id ? this.$t(`areas.${this.metaItem.area.id}.name`) : ''
      businessTypeName = this.metaItem.business_type.id ? this.$t(`business_types.${this.metaItem.business_type.id}.name`) : ''
      title = this.$t('text_e9lq').replace(':castName', castName).replace(':areaName', areaName).replace(':businessTypeName', businessTypeName);
    }
    return {
      title: title ? title : 'Tokyo Escort OTOME',
      titleTemplate: '',
      meta: [
        { hid: 'og:title', property: 'og:title', content: title ? title : 'Tokyo Escort OTOME' },
        { hid: 'description', name: 'description', content: this.$t('text_8sud').replace(/:castName/g, castName) },
        { hid: 'og:description', property: 'og:description', content: this.$t('text_8sud').replace(/:castName/g, castName) }
      ],
    }
  },
  data() {
    return {
      isGuest: !this.$store.state.auth.loggedIn,
      guestAlert: false,
      photoUrl: process.env.APP_PHOTO_URL,
      urlHash: '',
      cast: '',
      castProfiles: [],
      castSchedule: {},
      errorHotelDataDialog: false,
      castShopLoading: false,
      profilePlayCategories: '',
      castProfilePlays: '',
      playShape: { 1: '○', 2: '△', 3: '×' },
      serviceCourses: [],
      serviceOptions: [],
      cast_used_cast_image_role: '',
      cast_image_url: profile_image,
      profile_image: profile_image,
      profile_ngImage: profile_ngImage,
      article_image: article_image,
      contain: false,
      fav: false,
      followerCount: 0,
      siteMemberSiteService: false,
      siteMemberSiteServiceData: [],
      mdiHeart: 'mdi-heart-outline',
      reserveScheduleDialog: false,
      reserveNavDialog: false,
      articleDialog: false,
      selectImageId: 0,
      photoDiaryArticleLoading: false,
      countPhotoDiaryArticle: 0,
      photoDiaryArticles: [],
      photoDiaryPage: 1,
      photoDiaryLimitItemValue: 9, // 1ページ毎の表示件数
      hashtagCategories: [],
      searchHashtags: [],
      getPhotoDiaryArticleCompleteFlag: false,
      onLikedArticleIdb: '',
      likedNicknames: [],
      castEvaluationTags: [],
      castEvaluationTagColors: [
        {
          color: 'rgba(238, 238, 238, 1.0)',
          textColor: '#a8a8a8',
          outlined: true,
        }, // 新人
        { color: '#FF0037', textColor: '#FFF', outlined: false }, // 60%以上
        { color: '#FF7796', textColor: '#FFF', outlined: false }, // 40%以上
        { color: '#FFE9EE', textColor: '#B22C27', outlined: false }, // 20%以上
        { color: '#FFF', textColor: '#B22C27', outlined: true }, // 1%以上
        { color: '#E6E5E5', textColor: '#333333', outlined: false }, // 1%未満
      ],
      newcomer: false, // 新人か？（評価件数が5未満）
      displayReviewsNum: 5, //クチコミ初期表示する数
      castReviews: [],
      isOnceLoadCastSchedule: false, // 出勤スケジュール初回読み込み完了フラグ
      imageViewDialog: false,
      recommendPointTagsDialog: false,
      reservations: [],
      castFreeMemoLoading: false,
      editFreeMemoDialog: false,
      castFreeMemos: [],
      postCastFreeMemo: [],
      newPostCastFreeMemo: '',
      freeMemoDate: '',
      likedPersons: [], // いいねした人の配列
      chatNgFlag: false,
      selectedArticleTargetId: '',
      loadedArticleImage: 0,
      selectedArticleTargetIdScroll: true,
      //pointHelpDialog: false,
      //AboutStarDialog: false,
      //toShopChatRoomLoading: false, // 店舗ルーム作成・移動用ローディング
      //toCastChatRoomLoading: false, // キャストルーム作成・移動用ローディング
      //chatAttentionDialog: false, // チャット注意ダイアログ表示状態(trueで表示)
      //chatFavAttentionDialog: false, // チャットお気に入り注意ダイアログ表示状態(trueで表示)
      //chatOpenAttentionDialog: false, // チャットルーム開放注意ダイアログ表示状態(trueで表示)
      freeTimeFrames: [],
      availableAreas: [],
      castInfo: [],
      scheduleInfo: [],
      reservationWaitlist: [],
      castScheduleLoading: false,
      shopAreaIds: [],
      shopMaps: [],
      ngAreaFlag: false,
      ngReservableFlag: false,
      isDummy: false, // 完全ダミー&&タレント非在籍キャスト
      isCompleteDummy: false, // 完全ダミー
      guestAlertMessage: '',
      castReviewSortTab: 0, // 1:標準、2:利用日時順、3:参考になった順
      // onlyFollowUserFlag: false, // フォローしているユーザーのみのクチコミを取得するかどうか
      castReviewLoading: false,
      defaultAddressNameArr: [],
      exceptReserveId: '',
      whatsAppUrl: `${process.env.WHATS_APP_URL}`,
      metaItem: {},
      totalCount: null,
      limitCount: 10,
    };
  },
  async asyncData({ $axios, store, params }) {
    if (!params.id) {
      return {
        metaItem: {}
      }
    }
    try {
      const email = store.state.auth.loggedIn === true ? store.state.auth.user.email : '';
      const response = await $axios.post('public/cast-profile', {
        email: email,
        urlHash: params.id,
        language: this.$i18n.locale,
        areaId: store.state.siteMemberData.area_ids || 6,
        siteMemberId: store.state.siteMemberData.id,
        loggedIn: store.state.auth.loggedIn,
      })
      // title、description用のデータ
      return {
        metaItem: {
          cast: { site_cast_name: response.data.site_cast_name },
          area: { id: response.data.shop.area_id },
          business_type: { id: response.data.shop.business_type_id },
        }
      }
    } catch (error) {
      console.error('asyncData error:', error);
      return {
        metaItem: {}
      }
    }
  },
  computed: {
    cupList: function () {
      return this.$store.state.call.cupList;
    },
    allServiceContentTagsTrue() {
      return (
        this.cast.shop.service_content_tag1 &&
        this.cast.shop.service_content_tag2 &&
        this.cast.shop.service_content_tag3 &&
        this.cast.shop.service_content_tag4
      );
    },
    // アンケートの回答数によって文言を切り替える
    recommendPointText: function() {
      return this.newcomer
        ? this.$t('text_9z0c')
        : null;
    },
    recommendPointTags: function() {
      const tag = _.map(this.cast.cast_tags, (item) => {
        return {
          id: item.id,
        };
      });
      return this.newcomer ? _.uniqBy(tag, 'id') : this.castEvaluationTags;
    },
    displayCastReviews() {
      return this.castReviews.slice(0, this.displayReviewsNum);
    },
    showSeeMoreReviewBtn() {
      return this.displayReviewsNum >= this.castReviews.length ? false : true;
    },
    isLoadingSchedule() {
      return Object.keys(this.scheduleInfo).length > 0 ? false : true;
    },
    translateText: function () {
      return {
        hourAgo: this.$t('hour_ago'),
        oneHourAgo: this.$t('one_hour_ago'),
      };
    },
  },
  watch: {
    // メイン画像を選択した画像に切り替える
    selectImageId: function () {
      this.cast_image_url = this.cast.images[this.selectImageId];
    },
    likedPersons: function () {
      if (this.likedPersons.length == 0) {
        setTimeout(
          () =>
            document
              .getElementById(this.onLikedArticleIdb)
              .scrollIntoView(true),
          200
        );
      }
    },
    /*
    pointHelpDialog: function() {
      if (this.pointHelpDialog) {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
      } else {
        const top = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    },
    */
  },
  created: async function () {
    if (
      Object.keys(this.$route.params).length > 0 &&
      this.$route.name &&
      this.$route.name.indexOf('cast-id___') !== -1
    ) {
      this.urlHash = this.$route.params.id;
    } else {
      this.urlHash = this.$store.state.castDialog.selectedCastUrlHash;
    }
    // キャストURLハッシュが空の場合は処理を中断する
    if (!this.urlHash) return;
    if (
      this.$store.state.bindCast.cast.length != 0 &&
      this.$route.params.id === this.$store.state.bindCast.cast.url_hash
    ) {
      this.cast = this.$store.state.bindCast.cast;
      // 新検索エラー対応 bindCast用
      if (
        this.cast.business_type_id &&
        this.cast.business_type_name &&
        this.cast.shop_name &&
        this.cast.min_course_minute &&
        this.cast.min_course_money
      ) {
        this.cast.shop = {
          business_type: {
            id: this.cast.business_type_id,
            name: this.cast.business_type_name,
          },
          name: this.cast.shop_name,
          min_course_minute: this.cast.min_course_minute,
          min_course_money: this.cast.min_course_money,
        };
        if (this.cast.thumbnail_url) {
          this.cast_image_url = this.cast.thumbnail_url;
        } else {
          this.cast_image_url = profile_image;
        }
      } else {
        if (this.$store.state.bindCast.cast.bigThumbnailUrl !== '') {
          this.cast_image_url = this.$store.state.bindCast.cast.bigThumbnailUrl;
        } else if (this.$store.state.bindCast.cast.thumbnailUrl !== '') {
          this.cast_image_url = this.$store.state.bindCast.cast.thumbnailUrl;
        }
      }
    }
    // お気に入り
    if (
      this.cast.site_members != null &&
      Object.keys(this.cast.site_members).length > 0
    ) {
      this.fav = true;
    }
    // step2の日付け再選択ボタンから来た場合もしくはモーダルのキャストプロフィールスケジュールが表示の場合はスケジュールを表示
    if (
      this.$route.query.schedule ||
      this.$store.state.castDialog.selectedCastProfileScheduleDialog
    ) {
      this.reserveScheduleDialog = true;
    }
    // 検索一覧から来た場合は予約ボタンを表示
    if (this.$route.query.reserve) {
      this.reserveNavDialog = true;
    }
    // if (this.$store.state.auth.loggedIn === true) {
    //   this.getFollowSiteMemberLimit();
    // }
    await this.getCast();
    this.getCastShop();
    this.formatCast();
    this.getCastFreeContentsMedia();
    this.getCastSchedule();
    this.getCastProfilePlays();
    this.getCastReviews();
    this.getReservations();
    this.getSiteMemberCastFreeMemos();
    this.getSearchItems();
    this.$store.dispatch('call/reserveBtnStatus', false);
  },
  methods: {
    /*
    _toShopChatRoom(shopId) {
      if (this.isGuest) {
        this.guestAlert = true;
        return;
      }
      this.toShopChatRoom(shopId);
      this.toShopChatRoomLoading = true;
    },
    */
    getSearchItems: function () {
      this.$axios
        .post('public/get-cast-photo-diary-search-items', {})
        .then((response) => {
          if (response.data) {
            this.hashtagCategories = response.data.hashtagCategories;
          }
        });
    },
    recommendPointTagColor: function(tag) {
      if (this.newcomer) return this.castEvaluationTagColors[0];
      else if (tag.rate >= 60) return this.castEvaluationTagColors[1];
      else if (tag.rate >= 40) return this.castEvaluationTagColors[2];
      else if (tag.rate >= 20) return this.castEvaluationTagColors[3];
      else if (tag.rate >= 1) return this.castEvaluationTagColors[4];
      else return this.castEvaluationTagColors[5];
    },
    formatCastProfiles(data) {
      const { age, bust, cup, experience_flag, height, hip, sm_type, waist } =
        data;
      const { service_content_tag1 } = data.shop;
      if (data.talent == null) {
        data.talent = [];
        data.talent['body_piercing_flag'] = null;
        data.talent['drink_type'] = null;
        data.talent['smoking_type'] = null;
        data.talent['tattoo_type'] = null;
        data.talent['under_hair_flag'] = null;
        data.talent['body_piercing_flag'] = null;
      }
      const {
        body_piercing_flag,
        drink_type,
        smoking_type,
        tattoo_type,
        under_hair_flag,
      } = data.talent;
      const defaultValue = this.$t('unanswered');
      const values = {
        bodyPiercing: { 0: this.$t('no'), 1: this.$t('yes') },
        cup: { ...this.$store.state.call.cupList },
        drink: {
          1: this.$t('text_0bja'),
          2: this.$t('text_7clh'),
          3: this.$t('text_8ueu'),
        },
        experience: { 0: this.$t('text_0aeq'), 1: this.$t('text_hkx6') },
        sm: {
          1: this.$t('very_sadistic'),
          2: this.$t('slightly_sadistic'),
          3: this.$t('normal'),
          4: this.$t('slightly_masochist'),
          5: this.$t('extreme_masochist'),
        },
        smoking: {
          1: this.$t('no'),
          2: this.$t('text_miy1'),
          3: this.$t('text_jfv6'),
        },
        tattoo: {
          1: this.$t('no'),
          2: this.$t('text_ic7l'),
          3: this.$t('text_a092'),
        },
        underHair: { 0: this.$t('no'), 1: this.$t('yes') },
      };
      const finder = (values, value) => {
        if (value === null) return defaultValue;
        if (!!value.toString()) {
          const index = Object.keys(values).find((key) => key == value);
          if (index) {
            return values[index];
          }
        }
        return defaultValue;
      };
      const formatter = (format, data) => {
        if (
          Object.keys(data).length ===
          Object.keys(data).filter((key) => !!data[key]).length
        ) {
          let value = format;
          Object.keys(data).forEach((key) => {
            value = value.replace(new RegExp('%{' + key + '}', 'g'), data[key]);
          });
          return value;
        }
        return defaultValue;
      };
      const result = [
        {
          label: this.$t('age'),
          value: formatter('%{age}', { age }),
        },
        {
          label: this.$t('height'),
          value: formatter('%{height}cm / %{heightFeet}ft', {
            height,
            heightFeet: this.cmToFeetAndInches(height),
          }),
        },
        {
          label: this.$t('bust'),
          value: formatter('%{bust}cm / %{bustInch}in  (%{cupLabel}cup)', {
            bust,
            bustInch: this.cmToInch(bust),
            cupLabel: finder(values.cup, cup),
          }),
        },
        {
          label: this.$t('waist'),
          value: formatter('%{waist}cm / %{waistInch}in', {
            waist,
            waistInch: this.cmToInch(waist),
          }),
        },
        {
          label: this.$t('hip'),
          value: formatter('%{hip}cm / %{hipInch}in', {
            hip,
            hipInch: this.cmToInch(hip),
          }),
        },
        {
          label: this.$t('text_5wii'),
          value: finder(values.experience, experience_flag),
        },
        {
          label: this.$t('sm_type'),
          value: finder(values.sm, sm_type),
        },
        {
          label: this.$t('smoking'),
          value: finder(values.smoking, smoking_type),
        },
        {
          label: this.$t('tattoo'),
          value: finder(values.tattoo, tattoo_type),
        },
        {
          label: this.$t('body_piercing'),
          value: finder(values.bodyPiercing, body_piercing_flag),
        },
        {
          label: this.$t('text_5mbk'),
          value: finder(values.drink, drink_type),
        },
      ];
      // アンダーヘアは、service_content_tag1（サービス内容タグ（脱ぎ）） が 以下以外の場合に表示する
      // 1: なし
      // 2: なし（オプションでランジェリー可能）
      // 3: なし（オプションでトップレス可能）
      // 5: トップレス
      const hideableUnderHair = [1, 2, 3, 5];
      if (!hideableUnderHair.includes(service_content_tag1)) {
        result.push({
          label: this.$t('text_2oxv'),
          value: finder(values.underHair, under_hair_flag),
        });
      }
      return result;
    },
    getCast: async function () {
      const email =
        this.$store.state.auth.loggedIn === true
          ? this.$store.state.auth.user.email
          : '';
      try {
        const [castProfile, castProfileSub] = await Promise.all([
          this.$axios.post('public/cast-profile', {
            email: email,
            urlHash: this.urlHash,
            language: this.$i18n.locale,
            areaId: this.$store.state.siteMemberData.area_ids || 6,
            siteMemberId: this.$store.state.siteMemberData.id,
            loggedIn: this.$store.state.auth.loggedIn,
          }),
          this.$axios.post('public/cast-profile-sub', {
            email: email,
            urlHash: this.urlHash,
            language: this.$i18n.locale,
            loggedIn: this.$store.state.auth.loggedIn,
          }),
        ]);
        // データを結合
        this.cast = Object.assign({}, castProfile.data, castProfileSub.data);

        // title、description
        if (this.$route.name && this.$route.name.indexOf('cast-id___') === -1) {
          this.metaItem = {};
        }
      } catch (error) {
        console.log('getCast error:', error);
        this.$router.replace('/error');
        throw error; // エラーを再度スローして、呼び出し元でcatchできるようにします。
      }
    },
    formatCast: function () {
      // 完全ダミーまたは、タレントなしダミーかどうか
      if (_.isEmpty(this.cast.talent) === true) {
        this.isCompleteDummy = true;
        this.isDummy = true;
      } else {
        this.isCompleteDummy = false;
        this.isDummy = this.cast.talent.enroll_flag === 0 ? true : false;
      }
      this.shopMaps = this.cast.shopMaps;
      this.photoDiaryArticles.push(...this.cast.photoDiaryArticle);
      this.photoDiaryPage += 1;
      if (this.cast.photoDiaryArticle.length < 9) {
        this.getPhotoDiaryArticleCompleteFlag = true;
      }
      this.cast.ng_courses = !this.cast.ng_courses ? '' : this.cast.ng_courses;
      this.cast.ng_options = !this.cast.ng_options ? '' : this.cast.ng_options;

      this.followerCount = this.cast.followerCount;
      this.castProfiles = this.formatCastProfiles(this.cast);
      this.siteMemberSiteService = true;
      this.countPhotoDiaryArticle = this.cast.photoDiaryArticle.length;
      this.$store.dispatch('siteMemberData/saveLikedPhotoDiaryLimit', {
        likedPhotoDiaryLimit: this.cast.likedPhotoDiaryLimit,
        maxLikedPhotoDiary: this.cast.maxLikedPhotoDiary,
      });

      // キャスト画像
      if (
        this.cast.images &&
        Object.keys(this.cast.images).length > 0 &&
        this.cast.images[0]
      ) {
        this.cast_image_url = this.cast.images[0];
      } else if (
        this.cast.shop_default_thumbnail_set.set_name.search('ng') == -1
      ) {
        this.cast_image_url = this.profile_ngImage;
      } else if (
        this.cast.shop_default_thumbnail_set.set_name.search('ng') != -1
      ) {
        this.cast_image_url = this.profile_image;
      }

      this.$store.dispatch('bindCast/saveCastData', this.cast);

      // お気に入り
      if (Object.keys(this.cast.site_members).length > 0) {
        this.fav = true;
      }

      if (this.cast.site_member_site_reservations_available_flag == 0) {
        // 予約不可キャストの場合、step2で表示ダイアログ出し分けのフラグ立てる
        this.$store.dispatch('dialog/reservationImpossibleMode', 1);
      } else {
        this.$store.dispatch('dialog/reservationImpossibleMode', 0);
      }
      this.getCountCastEvaluation();
      this.getCastEvaluationTags();
      this.getLikedNicknames(this.cast.photoDiaryArticle);
    },
    getCastShop: async function () {
      this.castShopLoading = true;
      try {
        const response = await this.$axios.post('public/cast-profile-shop', {
          urlHash: this.urlHash,
          language: this.$i18n.locale,
          loggedIn: this.$store.state.auth.loggedIn,
        });

        this.cast = Object.assign({}, this.cast, response.data);

        this.siteMemberSiteServiceData = this.cast.siteServiceData;

        this.defaultAddressNameArr = this.formatDispatchableAreas(
          this.cast.dispatchable_areas
        );

        this.serviceCourses = this.cast.shop.services.filter(
          ({ category }) => category === 2
        );
        this.serviceOptions = this.cast.shop.services.filter(
          ({ category }) => category === 4
        );
        this.profilePlayCategories = this.cast.shop.profile_play_categories;
      } catch (error) {
        console.log('getCastShop error:', error);
      } finally {
        this.castShopLoading = false;
      }
    },
    getCastFreeContentsMedia: async function () {
      try {
        const response = await this.$axios.post(
          'public/cast-profile-free-contents-media',
          {
            urlHash: this.urlHash,
            language: this.$i18n.locale,
            loggedIn: this.$store.state.auth.loggedIn,
          }
        );
        this.cast = Object.assign({}, this.cast, response.data);
      } catch (error) {
        console.log('getCastFreeContentsMedia error:', error);
      }
    },
    getCastSchedule: async function () {
      this.castScheduleLoading = true;
      if (!this.$store.state.auth.loggedIn || this.isCompleteDummy === true) {
        await this.$axios
          .post(
            'public/cast-schedule-public',
            {
              urlHash: this.urlHash,
              areaId: this.$store.state.siteMemberData.area_ids,
              selectPlace: 'selected-hotel',
            },
            {}
          )
          .then((response) => {
            this.scheduleInfo = response.data['scheduleInfo'];
            this.isOnceLoadCastSchedule = true;
          })
          .catch((error) => {
            console.log('getCastSchedule error:', error);
          })
          .finally(() => {
            this.castScheduleLoading = false;
          });
      } else {
        // 予約変更。予約IDを無視する
        this.exceptReserveId = this.$route.query.reservation_id;
        const accessToken = this.$auth.$storage.getUniversal(
          '_token.auth0',
          true
        );
        await this.$axios
          .post(
            'private/cast-schedule',
            {
              urlHash: this.urlHash,
              areaId: this.$store.state.siteMemberData.area_ids,
              siteMemberId: this.$store.state.siteMemberData.id,
              selectPlace: this.$store.state.siteMemberData.selectPlace,
              selectHotelId: this.$store.state.siteMemberData.selectedHotelId,
              homeAreaId: this.$store.state.siteMemberData.home_area_id,
              homeSubAreaId: this.$store.state.siteMemberData.home_sub_area_id,
              exceptReserveId: this.$store.state.call
                .changeReservationId || '',
              currentCourseMinute: this.$store.state.call
                .changeReservationCourseMinute || '',
            },
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          )
          .then((response) => {
            this.castInfo = response.data['castInfo'];
            this.scheduleInfo = response.data['scheduleInfo'];
            this.freeTimeFrames = response.data['freeTimeFrames'];
            this.availableAreas = response.data['availableAreas'];
            this.reservationWaitlist = response.data['reservationWaitlist'];
            this.shopAreaIds = response.data['shopAreaIds'];
            this.ngAreaFlag = response.data['ngAreaFlag'];
            this.ngReservableFlag = response.data['ngReservableFlag'];
            this.isOnceLoadCastSchedule = true;
          })
          .catch((error) => {
            console.log('getCastSchedule error:', error);
          })
          .finally(() => {
            this.castScheduleLoading = false;
            // 初期化
            this.$store.dispatch('call/commitChangeReservationId', '')
            this.$store.dispatch('call/commitChangeReservationCourseMinute', '')
          });
      }
    },
    getCastProfilePlays: async function () {
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      await this.$axios
        .post(
          'public/cast-profile-plays',
          {
            urlHash: this.urlHash,
            language: this.$i18n.locale,
          },
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.castProfilePlays = response.data;
        })
        .catch((error) => {
          console.log('getCastProfilePlays error:', error);
        });
    },
    getCastReviews: async function(limit) {
      let language;
      if (this.$i18n.locale == 'zh-cn') {
        language = 'zh-CN';
      } else if (this.$i18n.locale == 'zh-tw') {
        language = 'zh-TW';
      } else if (this.$i18n.locale == 'ko') {
        language = 'ko';
      } else {
        language = this.$i18n.locale;
      }
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      const email =
        this.$store.state.auth.loggedIn === true
          ? this.$store.state.auth.user.email
          : '';
      if (!limit) {
        this.castReviewLoading = true;
        this.castReviews = [];
      } else {
        // 2秒クールタイムを必ず置く
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      await this.$axios
        .post(
          'public/get-cast-review',
          {
            urlHash: this.urlHash,
            siteMemberId: this.$store.state.siteMemberData.id,
            email: email,
            language: language,
            limit: limit ? limit : 0,
          },
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        .then((response) => {
          let filterData
          if (limit) {
            filterData = response.data.castReviews.filter((item) => !(this.castReviews.map((item) => item.id)).includes(item.id));
            this.castReviews = [...this.castReviews, ...filterData];
          } else {
            this.castReviews = response.data.castReviews;
          }
          this.totalCount = response.data.totalCount ? response.data.totalCount : this.castReviews.length;
        })
        .catch((error) => {
          console.log('getCastReviews error:', error);
        })
        .finally(() => {
          this.castReviewLoading = false;
        });
    },
    getPhotoDiaryArticle: async function () {
      this.photoDiaryArticleLoading = true;
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      const email =
        this.$store.state.auth.loggedIn === true
          ? this.$store.state.auth.user.email
          : '';
      await this.$axios
        .post(
          'public/get-cast-photo-diary-articles',
          {
            email: email,
            urlHash: this.urlHash,
            page: this.photoDiaryPage,
            limitItemValue: this.photoDiaryLimitItemValue,
            searchHashtags: this.searchHashtags,
          },
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            if (response.data.articles) {
              this.photoDiaryArticles.push(...response.data.articles);
              this.$store.dispatch(
                'bindCast/savePhotoArticleCastData',
                response.data.articles
              );
              this.getLikedNicknames(response.data.articles);
              this.photoDiaryPage += 1;
              if (response.data.articles.length < 9) {
                this.getPhotoDiaryArticleCompleteFlag = true;
              }
            } else {
              this.getPhotoDiaryArticleCompleteFlag = true;
            }
          }
          this.photoDiaryArticleLoading = false;
        })
        .catch((error) => {
          console.log('getPhotoDiaryArticle error:', error);
          this.photoDiaryArticleLoading = false;
        });
    },
    getLikedNicknames: function (articles) {
      var limit = 3;
      articles.forEach((article) => {
        var {
          site_member_like_cast_photo_diary_article,
          talent_like_cast_photo_diary_article,
        } = article;
        var persons = []
          .concat(
            site_member_like_cast_photo_diary_article,
            talent_like_cast_photo_diary_article
          )
          .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
          .filter((v) => !!v.nickname);

        var date = [];
        persons
          .filter((v, i) => i < limit)
          .forEach((v) => {
            // キャスト
            if (
              v.liked_talent_id &&
              v.shop &&
              v.shop.inbound_site_public_flag === 1
            ) {
              if (v.site_member_site_public_flag === 1) {
                if (v.site_cast_name) {
                  date.push({
                    name: v.site_cast_name,
                    url_hash: v.url_hash,
                    site_member_id: '',
                    likedPersonsDialog: false,
                  });
                } else if (v.nickname) {
                  date.push({
                    name: v.nickname,
                    url_hash: v.url_hash,
                    site_member_id: '',
                    likedPersonsDialog: false,
                  });
                } else {
                  date.push({
                    name: this.$t('nickname_not_set'),
                    url_hash: v.url_hash,
                    site_member_id: '',
                    likedPersonsDialog: false,
                  });
                }
              } else {
                if (v.site_cast_name) {
                  date.push({
                    name: v.site_cast_name,
                    url_hash: '',
                    site_member_id: '',
                    likedPersonsDialog: true,
                  });
                } else if (v.nickname) {
                  date.push({
                    name: v.nickname,
                    url_hash: '',
                    site_member_id: '',
                    likedPersonsDialog: true,
                  });
                } else {
                  date.push({
                    name: this.$t('nickname_not_set'),
                    url_hash: '',
                    site_member_id: '',
                    likedPersonsDialog: true,
                  });
                }
              }
              // 会員
            } else if (v.site_member_id) {
              if (!v.site_member_deleted_at) {
                if (v.nickname) {
                  date.push({
                    name: v.nickname,
                    url_hash: '',
                    site_member_id: v.site_member_id,
                    likedPersonsDialog: false,
                  });
                } else {
                  date.push({
                    name: this.$t('nickname_not_set'),
                    url_hash: '',
                    site_member_id: v.site_member_id,
                    likedPersonsDialog: false,
                  });
                }
              } else {
                date.push({
                  name: this.$t('unsubscribed_user'),
                  url_hash: '',
                  site_member_id: '',
                  likedPersonsDialog: true,
                });
              }
            }
          });
        if (persons.length > limit) {
          date.push({
            name: '他',
            url_hash: '',
            site_member_id: '',
            likedPersonsDialog: true,
          });
        }
        this.likedNicknames['articleId' + article.id] = [];
        if (date.length > 0) {
          this.likedNicknames['articleId' + article.id].push(...date);
        }
      });
    },
    /**
     * emitから渡されたfavoriteをfavに代入
     */
    emitFav: function (favorite) {
      this.fav = favorite;
    },
    openArticle: function (targetId) {
      // this.articleDialog = true;
      this.$refs.castProfilePhotoDiary.articleDialog = true;
      this.selectedArticleTargetIdScroll = true;
      if (this.selectedArticleTargetId) {
        setTimeout(
          () => document.getElementById(targetId).scrollIntoView(true),
          300
        );
        this.selectedArticleTargetIdScroll = false;
      } else {
        this.selectedArticleTargetId = targetId;
      }
    },
    articleDialogImageLoad: function () {
      this.loadedArticleImage++;
      this.$nextTick(() => {
        setTimeout(() => {
          if (
            this.loadedArticleImage == this.photoDiaryArticles.length &&
            this.selectedArticleTargetIdScroll == true
          ) {
            document
              .getElementById(this.selectedArticleTargetId)
              .scrollIntoView(true);
            this.selectedArticleTargetIdScroll = false;
          }
        }, 500);
      });
    },
    getCountCastEvaluation: function() {
      const email =
        this.$store.state.auth.loggedIn === true
          ? this.$store.state.auth.user.email
          : '';
      this.$axios
        .post(
          'public/count-cast-evaluation',
          {
            email: email,
            castId: this.cast.id,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.newcomer = response.data < 5;
        })
        .catch((error) => {
          console.log('getCountCastEvaluation error:', error);
        });
    },
    getCastEvaluationTags: function() {
      const email =
        this.$store.state.auth.loggedIn === true
          ? this.$store.state.auth.user.email
          : '';
      this.$axios
        .post(
          'public/cast-evaluation-tags',
          {
            email: email,
            castId: this.cast.id,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.castEvaluationTags = response.data;
        })
        .catch((error) => {
          console.log('getCastEvaluationTags error:', error);
        });
    },
    seeMoreCastReviews: function() {
      this.limitCount = this.limitCount + 5
      this.getCastReviews(this.limitCount);
      // クチコミもっとみる押されたときの増やすリストの数を加算する
      this.displayReviewsNum += 5;
      if (this.displayReviewsNum > this.castReviews.length) {
        this.displayReviewsNum = this.castReviews.length;
      }
    },
    serviceData: function (data) {
      return data.replace(/\\/g, '&yen;');
    },
    getReservations: function () {
      if (!this.$store.state.auth.loggedIn) return;
      this.$axios
        .post(
          'private/cast-reservations',
          {
            tel: this.$store.state.siteMemberData.tel,
            urlHash: this.urlHash,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.reservations = response.data;
        })
        .catch((error) => {
          console.log('getReservations error:', error);
        });
    },
    yearCheck: function (date, lastDate) {
      if (
        lastDate &&
        moment(date).format('YYYY') ===
          moment(lastDate.start_datetime).format('YYYY')
      ) {
        return false;
      } else {
        return true;
      }
    },
    getSiteMemberCastFreeMemos: function () {
      if (!this.$store.state.auth.loggedIn) return;
      this.castFreeMemoLoading = false;
      this.$axios
        .post(
          'private/get-cast-free-memos',
          {
            email: this.$store.state.auth.user.email,
            urlHash: this.urlHash,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.castFreeMemos = response.data.freeMemoData;
          this.postCastFreeMemo = response.data.editFreeMemoData;
          this.freeMemoDate = response.data.freeMemoDate;
          this.castFreeMemoLoading = true;
          this.$store.dispatch('loading/save', false);
        })
        .catch((error) => {
          getReservationsconsole.log(
            'getSiteMemberCastFreeMemos error:',
            error
          );
        });
    },
    clickEditFreeMemoButton: function () {
      if (!this.editFreeMemoDialog) {
        this.editFreeMemoDialog = true;
      }
    },
    saveEditFreeMemo: async function () {
      const postData = {
        email: this.$store.state.auth.user.email,
        cast_id: this.cast.id,
        newFreeMemo: this.newPostCastFreeMemo,
        castFreeMemos: Object.assign({}, this.castFreeMemos),
        postCastFreeMemo: Object.assign({}, this.postCastFreeMemo),
      };
      this.$store.dispatch('loading/save', true);
      this.editFreeMemoDialog = false;
      await this.$axios
        .post(`private/save-cast-free-memos`, postData, {
          headers: {
            Authorization: this.$auth.$storage.getUniversal(
              '_token.auth0',
              true
            ),
          },
        })
        .then(async (response) => {
          if (response.data.statusCode == 200) {
            this.newPostCastFreeMemo = '';
            this.getSiteMemberCastFreeMemos();
          } else {
            this.$store.dispatch('loading/save', false);
          }
        })
        .catch((error) => {
          getReservationsconsole.log('saveEditFreeMemo error:', error);
          this.$router.push('/error');
        });
    },
    /**
     * キャストチャットルームIDを取得してルームへ移動
     */
    /*
    toCastChatRoom(castId) {
      this.toCastChatRoomLoading = true;
      this.$axios
        .post(
          'private/get-cast-room-id',
          {
            email: this.$store.state.auth.user.email,
            castId: castId,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then(async (response) => {
          if (response.data.statusCode == 200) {
            this.$router.push('/chat/castRoom/' + response.data.roomId);
          }
          this.toCastChatRoomLoading = false;
        })
        .catch((error) => {
          this.$router.push('/error');
        });
    },
    */
    getScopeAnnotation(scope) {
      if (scope === 1) return this.$t('text_ip6p');
      if (scope === 2) return this.$t('text_wm8t');
      if (scope === 3) return this.$t('text_ekq8');
      return '';
    },
    onBodyHashtagClicked(tag) {
      this.photoDiaryArticles = [];
      this.photoDiaryPage = 1;
      this.$store.dispatch('bindCast/deletePhotoArticleCastData', []);
      this.searchHashtags = [tag];
      this.getPhotoDiaryArticle(true);
    },
    checkChatAttentionDialog: function() {
      if (this.$store.state.auth.loggedIn === true) {
        this.chatAttentionDialog = true;
      } else {
        this.isGuestDialog('ログイン/会員登録するとチャットが可能です');
        this.$refs.guestAlert.dialog = true;
      }
    },
    formatDispatchableAreas: function (dispatchableAreas) {
      const addressNames = _.flatMap(dispatchableAreas, (item) => {
        return item;
      }).map((v) => {
        return v.address;
      });
      return addressNames;
    },
    openGestDialog() {
      if (this.isGuest) {
        this.isGuestDialog(this.$t('login_text_5'));
        return;
      }
    },
    getRankingTagsStyleObj() {
      const target = document.getElementById('prof-photo__item');
      if (!target) return;
      const position = target.clientHeight + 10;
      return {
        bottom: `${String(position)}px`,
      };
    },
    dispayCourseName: function (item) {
      //「web予約名」を表示する。未定義もしくはnull・空の場合はサービス名を表示
      console.log(item);
      if (item.course[0] && item.course[0].offical_site_display_name) {
        return item.course[0].offical_site_display_name;
      } else {
        return item.course_name;
      }
    },
  },
  filters: {
    photoDiaryArticleMoment: function (date) {
      if (
        moment(date).format('YYYY-MM-DD HH:mm:ss') >
        moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
      ) {
        return translateText.oneHourAgo;
      } else if (
        moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ) {
        return moment().diff(date, 'hours') + ' ' + translateText.hourAgo;
      } else {
        return moment(date).format('M/D');
      }
    },
    moment: function (date) {
      const [y, m, d] = moment(date).format('YYYY-MM-DD').split('-');
      const _m = +m - 1;
      const _d = +d;
      // 曜日は一旦消し
      //const day = '日月火水木金土'.charAt(new Date(y, _m, _d).getDay());
      //let dd = `${moment(date).format('M/D')}  (${day})   ${moment(
      //  date
      //).format('HH:mm')}`;
      let dd = `${moment(date).format('M/D')}   ${moment(date).format(
        'HH:mm'
      )}`;
      return dd;
    },
    year: function (date) {
      return moment(date).format('YYYY');
    },
    memoCreatedTime: function (date) {
      let dd = `${moment(date).format('MM/DD/YYYY')}`;
      return dd;
    },
    addComma: function (value) {
      if (!value) return '';
      return value.toLocaleString();
    },
  },
};
</script>

<style lang="scss">
.list-reservations.v-list--disabled,
.list-freememo.v-list--disabled {
  h2 {
    color: rgba(0, 0, 0, 0.2);
  }
}
</style>

<style scoped>
h2 {
  color: var(--main-color);
}
.content-box-header {
  font-size: var(--read-font-size-m);
  line-height: var(--read-font-size-m);
  border-left: 0 !important;
}

/* プロフィール画像 */
.prof-photo__item {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0.36) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0.36) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
}
.prof-photo__page-back-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.prof-photo__page-back-button {
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(100, 100, 100, 0.3);
  border-radius: 100%;
}
.prof-photo__text {
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.prof-photo__review-table {
  color: #fff;
  font-size: var(--read-font-size-s);
}
.prof-photo__review-table tr th {
  font-weight: bold;
}
.prof-photo__review-table td,
.prof-photo__review-table th {
  border: solid 1px;
  text-align: center;
  padding: 0 0.2rem;
}
.prof-photo__action-btn {
  background: #fff;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  margin: 0 0 0.5rem auto;
  position: relative;
  right: 1rem;
  position: absolute;
  right: 15px;
  bottom: 60px;
}

/* 予約ナビゲーション */
.cv-action-nav {
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  bottom: 3.5rem;
  width: 100%;
  max-width: 1024px;
  background: rgba(255, 255, 255, 0.9);
  height: 4rem;
  z-index: 3;
  box-shadow: 0 -0.1rem 2px #e4e4e4;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}
[class^='cv-action-nav__btn-'] {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  border-radius: 1.8rem;
  border: solid 1px;
  font-size: var(--read-font-size-m);
  width: 38%;
  height: 2rem;
}
[class^='cv-action-nav__btn-default'] {
  background: #fff;
  color: var(--read-font-color-primary);
}
.cv-action-nav__btn-default--fav {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--main-color);
  border-color: var(--main-color);
  border: solid 1px;
}

/* 自己紹介 */
.prof-self-info__msg {
  font-size: var(--read-font-size-m);
  color: var(--read-font-color-primary);
  line-height: 1.6;
}

/* サービス */
.shop-concept {
  color: var(--main-color);
  font-weight: bold;
  border: 2px solid var(--main-color);
  padding: 5px;
  margin: 5px 16px;
}

/* 基本情報 */
.prof-self-basic-info__item {
  font-size: var(--read-font-size-s);
  font-weight: bold;
}

/* おすすめポイント */
.prof-tag {
  height: 26px;
  width: 48%;
  margin: 0.5%;
  padding: 0 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  display: inline-block;
  text-align: center;
  color: var(--read-font-color-sub);
}

.prof-tag-guide {
  margin: 8px auto;
  width: 80%;
  display: block;
  text-align: center;
  font-size: 10px;
}

.prof-tag-guide_border-gray {
  border-color: #ffcbd7 !important;
}

/* 写メ日記 */
.prof-photo-diary__header {
  position: fixed;
  height: 46px;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.prof-photo-diary__wrapper {
  border-radius: 0px;
  cursor: pointer;
}
.prof-photo-diary__subject {
  color: var(--read-font-color-sub);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prof-photo-diary__list-btn {
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
  color: var(--main-color);
  font-weight: bold;
}
.prof-cast-review__list-btn {
  width: 140px;
  height: 36px;
  padding: 0 16px;

  border-radius: 4px;
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
  color: var(--main-color);
  background-color: #fff;
  font-weight: bold;
  cursor: default;
  font-size: var(--read-font-size-s);

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  /* margin-bottom: 15px; */
  letter-spacing: 0.0892857143em;
}

/* プレイデータ */
.prof-play-info__header {
  color: var(--read-font-color-primary);
  font-size: var(--read-font-size-m);
  font-weight: bold;
}
.prof-play-info__box {
  display: inline-block;
  width: 48%;
  margin: 1%;
  text-align: center;
  vertical-align: top;
  color: var(--read-font-color-sub);
}
.prof-play-info__box dt {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  padding: 5px;
  border: solid 1px #d4d3cf;
  background: var(--main-bg-color);
  line-height: 1.5;
  border-radius: 7px 7px 0 0;
  color: #fff;
  background: linear-gradient(to right, #ffa4b7, #ff7796);
}
.prof-play-info__box dd {
  font-weight: bold;
  line-height: 1.5;
  padding: 5px;
  border: solid 1px #d4d3cf;
  border-top: none !important;
  background: #fff;
  border-radius: 0 0 7px 7px;
  color: var(--main-color);
}
.review_list-leave-active,
.review_list-enter-active {
  transition: opacity 0.5s, transform 0.5s ease;
}
.review_list-leave-to,
.review_list-enter {
  opacity: 0;
  transform: translateY(-50px);
}
.review_list-leave,
.review_list-enter-to {
  opacity: 1;
}
.review_list-move {
  transition: transform 0.5s;
}
.v-dialog__content--active >>> .image_view {
  margin: 0px !important;
}

/* お店詳細料金表 */
div.service_table >>> .v-data-table__wrapper {
  border: none !important;
}
div.service_table >>> table {
  border: 1px solid #ccc;
  border-collapse: separate !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-spacing: 0;
  border-color: #fff;
  overflow: hidden;
}
div.service_table >>> table td {
  font-size: 13px;
  background: #fff !important;
  color: var(--color-2) !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding: 10px;
}
div.service_table >>> table tr:first-child td {
  color: #fff;
  background: var(--main-color);
}
div.service_table >>> table tr td {
  background: #ffe6e9;
  color: var(--main-color);
}
div.service_table >>> table tr td:first-child {
  font-size: 12px;
  width: 45%;
}
div.service_table >>> table tr td:last-child {
  font-weight: bold;
}
div.service_table >>> table tr:last-child td {
  border-bottom: none;
}
div.service_table >>> table .charge-td {
  background: var(--color-1) !important;
  color: #fff !important;
  font-weight: bold !important;
}

/* 予約一覧 */
.cast-prof-reserve-list__year {
  font-size: var(--read-font-size-s);
  color: #fff;
  font-weight: bold;
  background-color: var(--main-color);
  padding: 2px;
  display: inline-block;
  border-radius: 3px;
}
.cast-prof-reserve-list__course {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* フリーメモ */
.prof-list-data >>> .cast-prof__header-edit-button {
  font-weight: bold;
  border-radius: 21px;
  color: var(--main-color);
  width: 156px;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  font-size: 13px;
}
.prof-list-data >>> .free_memo_area {
  white-space: pre-line;
  background: #fff;
  color: var(--read-font-color-primary);
  font-size: 14px;
  font-weight: bold;
}
.mx-auto >>> .cast-prof-memo-dialog__cancel-button {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  background-color: #f5f5f5;
}
.mx-auto >>> .cast-prof-memo-dialog__save-button {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  background-color: var(--main-color) !important;
}
.mx-auto >>> .cast-prof-memo-dialog__date {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  color: var(--read-font-color-sub);
}
.edit_memo_area {
  width: 98%;
}
.article-liked {
  margin: 4px;
  color: var(--read-font-color-sub);
  font-weight: bold;
}
.article-liked > span > div > a {
  text-decoration: none;
}
.article-title {
  margin: 4px 8px;
  color: var(--read-font-color-primary);
  font-weight: bold;
}
.usr-photo-diary__article-body {
  overflow: hidden;
  color: var(--read-font-color-primary);
  font-size: var(--read-font-size-s);
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre-line;
}
.cast-like-btn-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.cast-like-btn-container ::v-deep .cast-like-button {
  width: 44px !important;
  height: 44px !important;
}
.cast-like-btn-container ::v-deep .cast-like-button__icon {
  font-size: 22px !important;
  top: 4px !important;
}
.cast-like-btn-container ::v-deep .cast-like-button__liked-count {
  top: 23px !important;
}
::v-deep .v-list-group__items > div {
  padding-bottom: 12px !important;
  min-height: 100px;
}
.image {
  position: relative;
}
.scope {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  font-size: 2.5vh;
  font-weight: bold;
  text-align: center;
}
.scope span {
  display: block;
  font-size: xx-small;
  line-height: 1.1;
  white-space: pre-line;
}
.scope_0 {
  display: none;
}
.scope_1 {
  background-color: rgba(0, 0, 255, 0.5);
  color: #fff;
}
.scope_2 {
  background-color: rgba(255, 255, 0, 0.5);
  color: #000;
}
.scope_3 {
  background-color: rgba(0, 255, 0, 0.5);
  color: #000;
}
.scope_out {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  transform: translateZ(0); /* iOSでのパフォーマンス対策 */
}

.scope_out_big {
  font-size: 3rem;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  transform: translateZ(0); /* iOSでのパフォーマンス対策 */
}

.scope_out_big span {
  font-size: 1rem;
}
.v-tab {
  font-size: 12px;
  color: #4b4b4b;
  min-width: 85px !important;
}
.cast-review-sort-tabs >>> .v-tab {
  font-size: 12px;
  color: #4b4b4b;
  padding: 0 10px !important;
}
.cast-review-sort-tabs >>> .v-input--selection-controls__input {
  margin: 0 !important;
  width: 20px !important;
}
.cast-review-sort-tabs >>> .v-input--is-disabled .v-label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.cast-review-sort-tabs >>> .v-input {
  margin-top: 6px !important;
}
.cast-review-sort-tabs >>> .v-label {
  font-size: 10px !important;
  font-weight: bold !important;
}
.cast-review-sort-tabs >>> .v-icon {
  font-size: 18px !important;
}
.cast-review-sort-tabs
  >>> .theme--light.v-input--selection-controls.v-input--is-disabled
  .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>
