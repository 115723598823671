<template>
  <!-- TODO: コンポーネントの粒度が大きすぎて保守性が悪いので、もっと小さい粒度でコンポーネントを切り分ける -->
  <v-layout wrap class="ma-0 fill-height">
    <!-- クチコミ投稿者情報・総合満足度 -->
    <v-row
      class="pa-0 ma-0 member-info"
      :class="{
        'member-info-grade-star-none': !displayStarGrade && !commentBoxHeightFixed
      }"
    >
      <v-col cols="3" class="pa-1">
        <v-avatar width="70px" height="70px">
          <v-img
            contain
            :src="siteMemberImageUrl()"
            style="border: 4px solid #858ac4;"
          />
        </v-avatar>
      </v-col>
      <v-col cols="9" class="pa-1" style="position: relative;">
        <div>
          <div class="d-flex align-center mb-2">
            <div class="site-member-name mr-5">
              {{ $t('text_U3v6') }} {{ castReview.site_member ? castReview.site_member.nickname : castReview.name }}
              <span>{{ castReview.site_member ? '' : ''}}</span>
            </div>
          </div>
          <div
            class="d-flex align-center mb-3"
            style="font-size: 10px;font-weight: bold;color: var(--read-font-color-sub);"
          >
            <span class="mr-2">{{ castReview.reviews_count }} {{ $t('text_C7cL') }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- クチコミ内容 -->
    <div v-if="castReview.comment" class="review-content">
      <v-card
        class="review-content-comment mb-4 pa-0"
        :outlined=true
        :style="{
          'height': (commentBoxHeightFixed) ? '195px' : 'auto',
          'padding-bottom': (commentBoxHeightFixed) ? '8px !important' : '30px !important',
        }"
      >
        <UserRankingHighlightBox
          v-if="castReview.site_member && castReview.site_member.rank"
          :rank="castReview.site_member.rank"
          style="padding-left:30px !important;"
        />
        <div class="pa-3 px-4">
          <div class="d-flex align-center review-content-container__satisfaction mb-1">
            <div class="review-content-container__satisfaction-text mr-2">{{ $t('text_S8tr') }}</div>
            <div class="review-content-container__satisfaction-rating mr-2">
              <v-rating
                background-color="#B22C27"
                color="#B22C27"
                class="px-0"
                length="5"
                dense
                size="20"
                :value="Number(castReview.satisfaction)"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display:inline-block;"
              />
            </div>
            <div class="review-content-container__overall-satisfaction">
              {{ Number(castReview.overall_satisfaction).toFixed(1) }}
            </div>
          </div>
          <!-- 本文 -->
          <div
            v-html="omittedTextLength ? castReview.comment : reviewOmittedText(castReview.comment, castReview.unfolding_flag)"
            style="white-space: pre-line;"
            :style="{
              'text-overflow': (commentBoxHeightFixed) ? 'ellipsis' : 'ellipsis',
              'border-radius': (commentBoxHeightFixed) ? '0' : '0',
              'overflow': (commentBoxHeightFixed) ? 'hidden' : 'hidden',
              'display': (commentBoxHeightFixed) ? '-webkit-box' : '-webkit-box',
              '-webkit-box-orient': (commentBoxHeightFixed) ? 'vertical' : 'vertical',
              '-webkit-line-clamp': (commentBoxHeightFixed) ? '4' : lineClamp,
            }"
          />
          <!-- 続きを読む -->
          <div
            v-if="!omittedTextLength && !castReview.unfolding_flag"
            v-on:click="castReview.unfolding_flag=true;lineClamp=''"
            class="text-center"
            style="color:var(--main-color);font-size:var(--read-font-size-s);font-weight:bold;"
          >
            {{ $t('text_Nt6w') }}
          </div>
          <div v-else-if="omittedTextLength && commentBoxHeightFixed && (castReview.comment).length >= 100">
            <div
              v-on:click="castReviewDialog()"
              class="text-center"
              style="color:var(--main-color);font-size:var(--read-font-size-s);font-weight:bold;"
            >
              {{ $t('text_Nt6w') }}
            </div>
            <v-dialog
              v-model="dialog"
              content-class="ma-0"
              max-width="400"
            >
              <v-card class="py-3 px-1" style="background:var(--sub-bg-color);">
                <!-- クチコミ投稿者情報・総合満足度 -->
                <v-row class="pa-0 ma-0 member-info">
                  <v-col cols="3" class="pa-1">
                    <v-avatar width="70px" height="70px">
                      <v-img
                        :src="siteMemberImageUrl()"
                        style="border: 4px solid #858ac4;"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="pa-1">
                    <div class="d-flex align-center mb-2" style="position: relative;">
                      <div class="site-member-name mr-2">
                        {{ $t('text_U3v6') }} {{ castReview.site_member ? castReview.site_member.nickname : castReview.name }}
                        <span>{{ castReview.site_member ? '' : '' }}</span>
                      </div>
                    </div>
                    <div
                      class="d-flex align-center mb-3"
                      style="font-size: 10px;font-weight: bold;color: var(--read-font-color-sub);"
                    >
                      <span class="mr-1">{{ castReview.reviews_count }} {{ $t('text_C7cL') }}</span>
                    </div>
                  </v-col>
                </v-row>
                <!-- クチコミ内容 -->
                <div v-if="castReview.comment" class="review-content">
                  <v-card
                    class="review-content-comment mb-4"
                    style="height: auto; padding-bottom: 30px !important;"
                    :outlined=true
                  >
                    <UserRankingHighlightBox
                      v-if="castReview.site_member && castReview.site_member.rank"
                      :rank="castReview.site_member.rank"
                    />
                    <div class="pa-3 px-4">
                      <div class="d-flex align-center review-content-container__satisfaction mb-1">
                        <div class="review-content-container__satisfaction-text mr-2">{{ $t('text_S8tr') }}</div>
                        <div class="review-content-container__satisfaction-rating mr-2">
                          <v-rating
                            background-color="#B22C27"
                            color="#B22C27"
                            class="px-0"
                            length="5"
                            dense
                            size="20"
                            :value="Number(castReview.satisfaction)"
                            half-icon="mdi-star-half-full"
                            half-increments
                            readonly
                            style="display:inline-block;"
                          />
                        </div>
                        <div class="review-content-container__overall-satisfaction">
                          {{ Number(castReview.overall_satisfaction).toFixed(1) }}
                        </div>
                      </div>
                      <div
                        v-html="reviewOmittedText(castReview.comment, true)"
                        style="white-space: pre-line;"
                      />
                      <div class="review-date mb-2 px-2" v-show="castReview.date">
                        <span>{{ $t('text_Y5ap') }}</span>
                        <span>{{ castReview.date | moment_date(this.$i18n.locale) }}</span>
                      </div>
                    </div>
                    <!-- 吹き出し -->
                    <div
                      class="speech-bubble"
                      :class="{
                        'speech-bubble-grade-star-none': !displayStarGrade && !commentBoxHeightFixed,
                        'bg-highlight': castReview.site_member && castReview.site_member.rank
                      }"
                    ></div>
                  </v-card>
                </div>
                <div class="text-center py-3">
                  <v-btn
                    small
                    elevation="0"
                    v-on:click="
                      castReviewDialog()
                    "
                    style="color: var(--main-color); font-weight: bold"
                    >{{ $t('text_wT2D') }}</v-btn
                  >
                </div>
              </v-card>
            </v-dialog>
          </div>
          <div class="review-date mb-2 px-2" v-show="castReview.date">
            <span>{{ $t('text_Y5ap') }}</span>
            <span>{{ castReview.date | moment_date(this.$i18n.locale) }}</span>
          </div>
        </div>
        <!-- 吹き出し -->
        <div
          class="speech-bubble"
          :class="{
            'speech-bubble-grade-star-none': !displayStarGrade && !commentBoxHeightFixed,
            'bg-highlight': castReview.site_member && castReview.site_member.rank
          }"
        ></div>
      </v-card>
    </div>
    <!-- 参考になったボタン -->
    <!-- <HelpfullButton
      :count="castReview.site_member_help_full_cast_review_count"
      :default="castReview.is_help_full"
      :cast-review-id="castReview.id"
      :site-member-id="castReview.site_member_id"
      :date="castReview.date"
      class="mb-4"
    /> -->
    <!-- キャストプロフィール・キャストスケジュール -->
    <v-row class="pa-0 ma-0">
      <v-col cols="5" class="pa-1">
        <CastImageCard
          @toCastProfileDialog="toCastProfile(cast)"
          :cast="castReview.cast"
          :executeParentMethod="true"
          :imageMaxHeight="240"
        />
      </v-col>
      <v-col cols="7" class="pa-1">
        <h2 style="color: #B22C27; font-size: 15px;">
          <CastSchedule :castSchedules="castReview.cast.limitedCastSchedules" />
        </h2>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
// import HelpfullButton from "~/components/pages/cast/HelpfullButton.vue";
import CastImageCard from "~/components/organisms/castImageCard/CastImageCard.vue";
import CastSchedule from "~/components/pages/castReview/CastSchedule.vue";
import IconGrade from "~/components/pages/common/IconGrade.vue";
import UserRankingHighlightBox from "~/components/pages/common/UserRankingHighlightBox.vue";

import profileDefaultImage from "~/assets/img/profile.jpg";

export default {
  components: {
    // HelpfullButton,
    CastImageCard,
    CastSchedule,
    IconGrade,
    UserRankingHighlightBox
  },
  props: {
    castReview: {
      type: Object,
      required: true,
    },
    commentBoxHeightFixed: {
      type: [Number, Boolean],
      default: false,
    },
    omittedTextLength: {
      type: Number,
    }
  },
  data() {
    return {
      isGuest: this.$store.state.auth.loggedIn,
      profileDefaultImage: profileDefaultImage,
      AWS_S3_URL: process.env.AWS_S3_URL,
      dialog: false,
      scrollX: 0,
      lineClamp: 10,
    }
  },
  methods: {
    toCastProfile() {
      const target = document.getElementsByClassName('cast-review-slide-wrapper');
      if (target.length) {
        this.$store.dispatch('castDialog/sliderScrollX', target[0].childNodes[0].scrollLeft);
        this.$store.dispatch('castDialog/sliderScrollXTargetId', 'cast-review-slide-wrapper');
      }
      this.toCastProfileDialog(this.castReview.cast);
    },
    toSiteMemberDialog() {
      if (!this.castReview.site_member) {
        return;
      }
      this.openSiteMemberDialog(this.castReview.site_member.id);
    },
    siteMemberImageUrl () {
      return this.castReview.site_member && this.castReview.site_member.image_filename
        ? `${this.AWS_S3_URL}site_members/${this.castReview.site_member.id}/mypage/photo/${this.castReview.site_member.image_filename}`
        : this.profileDefaultImage;
    },
    castReviewDialog () {
      const slide = document.getElementsByClassName('cast-review-slide-wrapper')[0].childNodes[0];
      if (!this.dialog) {
        this.scrollX = slide.scrollLeft;
        this.dialog = true;
        setTimeout(() => {
          slide.scrollLeft = this.scrollX;
        }, 1);
      } else {
        this.dialog = false;
      }
    }
  },
  computed: {
    displayStarGrade() {
      return this.castReview.site_member
          && this.castReview.site_member.star_public_flag
          && this.castReview.site_member.grade_public_flag
    }
  }
}
</script>

<style lang="scss" scoped>
.member-info {
  position: relative;
  min-height: 112px;
  .site-member-name {
    font-size: 1rem;
    font-weight: bold;
    color: var(--main-title-color);
  }
  .right-arrow {
    position: absolute;
    right: 0%;
    top: 7%;
    border-top: solid 3px #BDBDBD;
    border-right: solid 3px #BDBDBD;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
  }
  ::v-deep .follow__btn {
    width: auto !important;
    height: 1.2rem !important;
    font-size: 0.625rem !important;
  }
}
.member-info-grade-star-none {
  min-height: 100px;
}
.review-content {
  position: relative;
  width: 100%;
  .review-content-container__satisfaction {
    padding: 0 !important;
    color: var(--main-color);
    font-weight: bold;
    .review-content-container__satisfaction-text {
      font-size: 16px;
    }
    .review-content-container__satisfaction-rating {
      padding-bottom: 2px;
      ::v-deep .v-icon {
        padding: 0 0 2px 0 !important;
      }
    }
    .review-content-container__overall-satisfaction {
      font-size: 22px;
      font-weight: bold;
    }
  }
  .review-content-comment {
    position: relative;
    border: none !important;
    width: 100%;
    background: #FFF;
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--main-title-color);
    border-radius: 15px;
    z-index: 3;
    .review-date {
      position: absolute;
      left: 2.5%;
      bottom: 0%;
      font-weight: bold;
      font-size: 0.75rem;
      color: var(--main-color);
    }
  }
  .speech-bubble {
    position: absolute;
    top: -45px;
    left: 46px;
    margin-left: -20px;
    border: 13px solid transparent;
    border-bottom: 40px solid #fff;
  }
  .speech-bubble-grade-star-none {
    top: -36px;
  }
  .bg-highlight {
    border-bottom: 40px solid #FFF9E5;
  }
}
.grade-icon {
  border-radius: 3px;
  background: #fff;
  font-size: 0.65rem;
  font-weight: bold;
  color: #333333;
  height: 26px;
  box-sizing: border-box;
}
::v-deep .helpfull-btn-container {
  width: 100% !important;
  .help_full__btn {
    width: 100% !important;
    height: 2rem !important;
    .helpfull-text {
      font-size: 10px;
      font-size: 1rem !important;
      font-weight: bold !important;
      margin-left: 10px !important;
    }
  }
}
::v-deep .new-cast-list__text {
  font-size: 0.625rem;
  font-weight: bold;
}
</style>