<template>
  <div style="position: relative;">
    <div class="d-flex align-center mb-2" style="position: relative;">
      <div class="site-member-name mr-2" style="max-width: 165px;">
        {{ name }}様
      </div>
      <FollowBtn 
        class="followBtn"
        :site_member_id="siteMemberId" 
        :is_follow="isFollowing"
        :is_followed="isFolowed"
      />
    </div>
    <div
      class="d-flex align-center mb-1" 
      style="font-size: 10px;font-weight: bold;color: var(--read-font-color-sub);"
    >
      <span class="mr-1">クチコミ{{ reviewCount }}件</span>
      <span class="mr-1">フォロワー{{ followerCount }}人</span>
      <span class="mr-1">フォロー中{{ followCount }}人</span>
    </div>
    <span class="right-arrow"></span>
  </div>
</template>

<script>
import FollowBtn from '~/components/pages/user/FollowBtn.vue';

export default {
  props: {
    siteMemberId: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isFollowing: {
      type: [Number, Boolean],
      default: false
    },
    isFolowed: {
      type: [Number, Boolean],
      default: false
    },
    reviewCount: {
      type: Number,
      required: true
    },
    followCount: {
      type: Number,
      required: true
    },
    followerCount: {
      type: Number,
      required: true
    },
  },
  components: {
    FollowBtn
  }
}
</script>

<style lang="scss" scoped>
.site-member-name {
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-title-color);
}
.right-arrow {
  position: absolute;
  right: 8%;
  top: 50%;
  border-top: solid 3px #BDBDBD;
  border-right: solid 3px #BDBDBD;
  width: 10px;
  height: 10px;
  transform: translateY(-50%) rotate(45deg);
}
.followBtn {
  position: absolute;
  right: 13%;
  top: 50%;
  transform: translateY(-50%);
}
::v-deep .follow__btn {
  width: 90px !important;
  height: 1.3rem !important;
  font-size: 0.625rem !important;
}
</style>