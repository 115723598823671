<template>
  <div>
    <!-- キャストの評価 -->
    <div class="d-flex align-center mb-2">
      <IconEvaluation class="mr-2" style="fill: var(--main-color);" />
      <div style="fontn-size:0.875rem;font-weight:bold;color:var(--main-color);">{{ $t('text_n4NL') }}</div>
    </div>
    <table class="cast-evaluation-table mb-2">
      <tbody>
        <tr v-if="overallSatisfaction" class="overall satisfaction">
          <td nowrap valign="top">{{ $t('text_E6d9') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="overallSatisfaction"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
        <tr v-if="treatmentSatisfaction" class="overall satisfaction">
          <td nowrap valign="top">{{ $t('text_P8pf') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="treatmentSatisfaction"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- キャストの魅力 -->
    <div class="d-flex align-center mb-2" v-if="attraction">
      <IconEvaluation class="mr-2" style="fill: var(--main-color);" />
      <div style="fontn-size:0.875rem;font-weight:bold;color:var(--main-color);">{{ $t('text_Jh9Q') }}</div>
    </div>
    <table class="cast-evaluation-table">
      <tbody>
        <tr v-if="evaluationLooks" class="satisfaction">
          <td nowrap valign="top">{{ $t('text_V4xU') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="evaluationLooks"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
        <tr v-if="evaluationStyle" class="satisfaction">
          <td nowrap valign="top">{{ $t('text_wK8R') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="evaluationStyle"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
        <tr v-if="evaluationPlay" class="satisfaction">
          <td nowrap valign="top">{{ $t('text_N7mB') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="evaluationPlay"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
        <tr v-if="evaluationCharacter" class="satisfaction">
          <td nowrap valign="top">{{ $t('text_Mr49') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="evaluationCharacter"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
        <tr v-if="evaluationCaring" class="satisfaction">
          <td nowrap valign="top">{{ $t('text_zU7a') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="evaluationCaring"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
        <!-- 旧施術満足度 -->
        <tr v-if="evaluation6" class="satisfaction">
          <td nowrap valign="top">{{ $t('text_k4Kw') }}</td>
          <td>
            <span class="rating">
              <v-rating
                :background-color="bgColor"
                :color="color"
                class="px-0"
                length="5"
                dense
                :size="size"
                :value="evaluation6"
                half-icon="mdi-star-half-full"
                half-increments
                readonly
                style="display: inline-block"
              ></v-rating>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import IconEvaluation from '~/assets/img/icon-evaluation.svg';
export default {
  components: {
    IconEvaluation
  },
  props: {
    overallSatisfaction: {
      type: Number,
      default: 0
    },
    treatmentSatisfaction: {
      type: Number,
      default: 0
    },
    evaluationLooks: {
      type: Number,
      default: 0
    },
    evaluationStyle: {
      type: Number,
      default: 0
    },
    evaluationPlay: {
      type: Number,
      default: 0
    },
    evaluationCharacter: {
      type: Number,
      default: 0
    },
    evaluationCaring: {
      type: Number,
      default: 0
    },
    // 旧施術満足度
    evaluation6: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 13
    },
    color: {
      type: String,
      default: "#B22C27"
    },
    bgColor: {
      type: String,
      default: "#B22C27"
    }
  },
  computed: {
    attraction() {
      return this.evaluationLooks || this.evaluationStyle || this.evaluationPlay ||
        this.evaluationCharacter || this.evaluationCaring || this.evaluation6;
    }
  }
}
</script>

<style lang="scss" scoped>
.cast-evaluation-table {
  color: var(--color-2);
  font-weight: bold;
  font-size: 0.75rem;
  .satisfaction {
    td:nth-child(1) {
      margin: 4px 0;
    }
    td:nth-child(2) {
      padding-left: 20px;
    }
  }
  .overall {
    td:nth-child(2) {
      padding-left: 44px;
    }
  }
  ::v-deep .v-icon {
    padding: 0 0 3px 0 !important;
  }
}
</style>