<template>
  <v-main
    id="user-profile-container"
    style="background: var(--new-main-bg-color);"
  >
    <v-card
      v-if="!Object.keys(user).length"
      class="ma-4 text-center"
      justify="center"
    >
      <!-- UI Component: LoadingImage -->
      <loading-image />
    </v-card>
    <Profile
      class="mb-2"
      v-if="Object.keys(user).length"
      v-model="selectedTab"
      :siteMemberId="user.id"
      :isFollow="user.is_follow"
      :tabIndex="selectedTab"
      :imageSrc="
        user.image_filename
          ? `${AWS_S3_URL}site_members/${user.id}/mypage/photo/${user.image_filename}`
          : profileDefaultImage
      "
      :nickName="user.nickname"
      :selfIntroduction="user.self_introduction"
      :star="user.star ? Number(user.star) : 0"
      :grade="user.site_member_grade_id"
      :country="user.country['country_name_'+selectLocale]"
      :frequentlyPlayedArea="user.frequently_played_area_text"
      :frequentlyPlayedGenreName="frequentlyPlayedGenreName"
      :frequentlyPlayedShopName="
        user.frequently_played_shop ? user.frequently_played_shop.name : ''
      "
      :favoriteCastCount="user.favorite_cast_count"
      :reviewCount="user.review_count"
      :followCount="user.follow_count"
      :followerCount="user.follower_count"
      :starPublicFlag="user.star_public_flag"
      :gradePublicFlag="user.grade_public_flag"
    />

    <div
      :class="{ 'mb-2': ![2, 3].includes(selectedTab) }"
      style="background: #F0F3F4;"
    >
      <div class="mx-4 pt-3 pb-1" v-if="Object.keys(user).length">
        <div class="d-flex align-center justify-space-between mb-3">
          <div
            v-if="selectedTab === 0"
            class="d-flex align-center"
            style="color:var(--main-color);font-weight:bold;font-size:1rem;"
          >
            <v-icon size="20" color="#B22C28" class="mr-2">mdi-heart</v-icon>
            {{ $t('favorite_cast') }}
          </div>
          <div
            v-if="selectedTab === 1"
            class="d-flex align-center"
            style="color:var(--main-color);font-weight:bold;font-size:1rem;"
          >
            <IconReview class="mr-2" style="fill: #B22C27;" />
            {{ $t('list_of_posted_reviews') }}
          </div>
          <!-- <div
            v-if="selectedTab === 2 || selectedTab === 3"
            class="d-flex align-center"
            style="color:var(--main-color);font-weight:bold;font-size:1rem;"
          >
            <IconMypage class="mr-2" style="fill: #B22C27;" />
            {{ selectedTab === 2 ? 'フォロー一覧' : 'フォロワー一覧' }}
          </div> -->
          <div
            v-show="currentItem.data.length > 0"
            class="pa-0 text-left"
            style="color:#707070;font-size:0.65rem;"
          >
            <span>
              {{
                $t('display_count', {
                  last: currentItem.data.length,
                  total: currentItem.total,
                })
              }}
            </span>
          </div>
        </div>
        <div class="user-page-sort-tab">
          <v-tabs
            class="scroll-container mb-2"
            v-model="currentItem.selectedOrderIndex"
            color="#B22C27"
            background-color="#F0F3F4"
            align-tabs="center"
            height="20px"
            @change="changeSelectItem"
          >
            <v-tab
              v-for="(item, index) in currentItem.orderItems"
              :key="item.label"
              :value="item.value"
              :class="{ 'first-tag': index === 0 }"

            >
              {{ item.label }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </div>

    <div style="background: var(--new-main-bg-color);margin-bottom: 70px;">
      <div
        v-if="currentItem.data.length == 0 && !currentItem.loading"
        class="pt-2 py-3 text-center"
        style="font-weight:bold;"
      >
        <span v-if="selectedTab == 0">{{ $t('text_fk8i') }}</span>
        <span v-if="selectedTab == 1"
          ><!-- {{ $t('there_are_no_public_cast_ratings') }} -->{{ $t('in_preparation') }}</span
        >
        <!-- <span v-if="selectedTab == 2">フォローしていません</span>
        <span v-if="selectedTab == 3">フォロワーがいません</span> -->
      </div>

      <!-- リスト表示 -->
      <template v-for="(item, index) in currentItem.data">
        <!--
          「クチコミ」「フォロー」「フォロワー」「お気に入り」
          の各項目、各リストごとに重複しないv-list-itemのkey名をつけないと、リアクティブが効かないので注意
         -->
        <v-list-item
          :key="`${selectedTab}-${index}`"
          :ripple="false"
          class="ma-0 pa-0"
          style="display: inline;"
        >
          <keep-alive>
            <component
              v-if="followOrFollowerTab"
              v-bind:is="currentItem.component"
              :param="item"
              :class="{ 'pb-10': index === currentItem.data.length - 1 }"
              :lastChild="index === currentItem.data.length - 1"
            >
            </component>
            <!-- クチコミは実装するまで準備中 -->
            <div v-else-if="selectedTab == 1">
              {{ $t('in_preparation') }}
            </div>
            <component
              v-else
              v-bind:is="currentItem.component"
              :param="item"
              :selectedTab="selectedTab"
              :class="{ 'pb-10': index === currentItem.data.length - 1 }"
            >
            </component>
          </keep-alive>
        </v-list-item>
      </template>
      <div v-if="currentItem.loading" class="mt-3">
        <!-- UI Component: LoadingImage -->
        <loading-image />
      </div>
    </div>

    <!-- キャスト予約 -->
    <CastReserveDialog />

    <!-- HelpFullReviewLimitDialog -->
    <HelpFullReviewLimitDialog />

    <!-- HelpFullReviewLimitDateDialog -->
    <HelpFullReviewLimitDateDialog />

    <v-overlay :value="this.$store.state.dialog.helpFullButtonOverlay">
      <loading-image />
    </v-overlay>
  </v-main>
</template>

<script>
import _ from 'lodash';
import profileDefaultImage from '~/assets/img/profile.jpg';
import Tab from '~/components/pages/user/Tab.vue';
import HelpfullButton from '~/components/pages/user/HelpfullButton.vue';
import FollowBtn from '~/components/pages/user/FollowBtn.vue';
import Review from '~/components/pages/user/Review.vue';
import Follower from '~/components/pages/user/follower.vue';
import Profile from '~/components/pages/user/Profile.vue';
import CastFollow from '~/components/pages/user/CastFollow.vue';
import CastReserveDialog from '~/components/pages/dialog/CastReserveDialog.vue';
import HelpFullReviewLimitDialog from '~/components/pages/dialog/HelpFullReviewLimitDialog.vue';
import HelpFullReviewLimitDateDialog from '~/components/pages/dialog/HelpFullReviewLimitDateDialog.vue';
import IconShop from '~/assets/img/icon-shop.svg';
import IconGenre from '~/assets/img/icon-genre.svg';
import IconReview from '~/assets/img/icon-review.svg';

export default {
  auth: false,
  components: {
    Tab,
    HelpfullButton,
    FollowBtn,
    Review,
    Follower,
    CastReserveDialog,
    HelpFullReviewLimitDialog,
    HelpFullReviewLimitDateDialog,
    IconShop,
    IconGenre,
    Profile,
    IconReview,
    CastFollow,
  },
  data() {
    return {
      profileDefaultImage: profileDefaultImage,
      AWS_S3_URL: process.env.AWS_S3_URL,
      // 「クチコミ」等　選択中の項目
      selectedTab: 0,
      // ユーザプロフ情報
      user: {},
      // 「クチコミ」「フォロー」「フォロワー」「お気に入り」項目ごとに
      //  現在のページ数、最終ページ数、全項目数を保持 APIのローディング状態、並び替えのプルダウンアイテムもそれぞれで保持
      list_data: [
        {
          // お気に入りキャスト
          data: [],
          total: 0,
          per_page: 0,
          current_page: 1,
          last_page: 0,
          loading: false,
          init_loading: false,
          api: 'public/get-site-member-favorite',
          component: 'CastFollow',
          selectedOrderIndex: 0,
          orderItems: [
            { label: this.$t('all'), usageHistory: 'all', value: 0 },
            { label: this.$t('text_69qk'), usageHistory: 'used', value: 1 },
            {
              label: this.$t('text_ue93'),
              usageHistory: 'notUsed',
              value: 2,
            },
          ],
        },
        {
          // 会員の投稿したクチコミ
          data: [],
          total: 0,
          per_page: 0,
          current_page: 1,
          last_page: 0,
          loading: false,
          init_loading: false,
          api: 'public/get-site-member-cast-review-userpage',
          component: 'Review',
          selectedOrderIndex: 0,
          orderItems: [
            { label: this.$t('order_of_use'), value: 'date', by: 'desc' },
            { label: this.$t('order_highest_rated'), value: 'evaluation', by: 'desc' },
            { label: this.$t('order_of_helpfulness'), value: 'help_full', by: 'desc' },
          ],
        },
        // {
        //   data: [],
        //   total: 0,
        //   per_page: 0,
        //   current_page: 1,
        //   last_page: 0,
        //   loading: false,
        //   init_loading: false,
        //   api: 'public/get-site-member-follow',
        //   component: 'Follower',
        //   selectedOrderIndex: 0,
        //   orderItems: [
        //     { label: '登録日順', value: 'registered_at', by: 'desc' },
        //     { label: 'クチコミの多い順', value: 'review', by: 'desc' },
        //     { label: 'フォロワーの多い順', value: 'follower', by: 'desc' },
        //   ],
        // },
        // {
        //   data: [],
        //   total: 0,
        //   per_page: 0,
        //   current_page: 1,
        //   last_page: 0,
        //   loading: false,
        //   init_loading: false,
        //   api: 'public/get-site-member-follower',
        //   component: 'Follower',
        //   selectedOrderIndex: 0,
        //   orderItems: [
        //     { label: '登録日順', value: 'registered_at', by: 'desc' },
        //     { label: 'クチコミの多い順', value: 'review', by: 'desc' },
        //     { label: 'フォロワーの多い順', value: 'follower', by: 'desc' },
        //   ],
        // },
      ],
      businessTypes: [],
      selectLocale: null,
    };
  },
  async created () {
    this.businessTypes = await this.getBusinessTypes();
    this.getUser();
    this.getFavoriteCasts();
  },
  mounted() {
    if (this.$i18n.locale === 'zh-cn') {
      this.selectLocale = 'zh_cn'
    } else if (this.$i18n.locale === 'zh-tw') {
      this.selectLocale = 'zh_tw'
    } else if (this.$i18n.locale === 'ko') {
      this.selectLocale = 'ko'
    } else {
      this.selectLocale = 'en'
    }
    // スクロールイベントセット
    const target = document.querySelectorAll(
      '.user-profile-dialog .v-dialog--active'
    );
    if (target && target.length) {
      target[0].addEventListener('scroll', this.pager);
      return;
    }
    window.addEventListener('scroll', this.pager);
  },
  watch: {
    selectedTab(newVal, old) {
      this.changeSelectItem();
    },
  },
  methods: {
    // v-select切り替えイベント
    changeSelectItem(val) {
      // 現在のアイテムをリセット
      this.currentItem.data.splice(0);
      // ページ番号を1に戻す。
      this.currentItem.current_page = 1;

      //再度API取得
      this.getApi(this.selectedTab);
    },
    pager() {
      const target = document.querySelectorAll(
        '.user-profile-dialog .v-dialog--active'
      );
      let scrollAmount = 0;
      let offsetHeight = 0;
      if (target && target.length) {
        // モーダル内のスクロール量
        scrollAmount = target[0].offsetHeight + target[0].scrollTop;
        offsetHeight = document.getElementById('user-profile-container')
          .offsetHeight;
      } else {
        // 通常画面内のスクロール量
        scrollAmount = window.pageYOffset + window.innerHeight;
        offsetHeight = document.body.clientHeight;
      }
      // ページ最下部に達した時
      if (
        scrollAmount >= offsetHeight &&
        this.currentItem.current_page < this.currentItem.last_page
      ) {
        // ページ番号足して再度API取得
        this.list_data[this.selectedTab].current_page++;

        this.getApi(this.selectedTab);
      }
    },
    getSiteMemberId: function() {
      let siteMemberId = this.$route.params.id ? this.$route.params.id : '';
      if (
        this.$store.state.siteMemberDialog.openDialog &&
        this.$store.state.siteMemberDialog.siteMemberId
      ) {
        siteMemberId = this.$store.state.siteMemberDialog.siteMemberId;
      }
      return siteMemberId;
    },
    getUser: async function() {
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      const loginSiteMemberId = this.$store.state.siteMemberData.id
        ? this.$store.state.siteMemberData.id
        : '';
      await this.$axios
        .post(
          'public/get-site-member',
          {
            id: loginSiteMemberId,
            userId: this.getSiteMemberId(),
            from: 'userpage',
          },
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {});
    },
    async getApi(selectedTab) {
      let param = {
        id: this.getSiteMemberId(),
        loggedInSiteMemberId: this.$store.state.siteMemberData.id,
      };

      switch (selectedTab) {
        case 0:
        case 1:
          param = Object.assign(param, {
            siteMemberId: this.$store.state.siteMemberData.id,
          });
          break;
      }

      const orderIndex = this.list_data[selectedTab].selectedOrderIndex;
      if (selectedTab !== 0) {
        // ページ番号、ソート順を代入
        param = Object.assign(param, {
          page: this.list_data[selectedTab].current_page,
          key: this.list_data[selectedTab].orderItems[orderIndex]
            ? this.list_data[selectedTab].orderItems[orderIndex].value
            : this.list_data[selectedTab].orderItems[0].value,
          by: this.list_data[selectedTab].orderItems[orderIndex]
            ? this.list_data[selectedTab].orderItems[orderIndex].by
            : this.list_data[selectedTab].orderItems[0].by,
        });

        if (this.list_data[selectedTab].loading) return;
        this.list_data[selectedTab].loading = true;
      } else {
        // お気に入りキャスト取得
        param = Object.assign(param, {
          usageHistory: this.list_data[selectedTab].orderItems[orderIndex]
            ? this.list_data[selectedTab].orderItems[orderIndex].usageHistory
            : this.list_data[selectedTab].orderItems[0].usageHistory,
        });
        if (this.list_data[0].loading) return;
        this.list_data[0].loading = true;
      }

      // axiosでAPI叩く
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      await this.$axios
        .post(this.list_data[selectedTab].api, param, {
          headers: {
            Authorization: `${accessToken}`,
          },
        })
        .then((response) => {
          // 現行仕様だと、お気に入りキャスト以外はページネーションでデータを取得する
          if (selectedTab !== 0) {
            // データ部
            this.list_data[selectedTab].data.push(...response.data.data);
            // ペジネーション情報
            this.list_data[selectedTab].total = response.data.total;
            this.list_data[selectedTab].per_page = response.data.per_page;
            this.list_data[selectedTab].current_page =
              response.data.current_page;
            this.list_data[selectedTab].last_page = response.data.last_page;
          } else {
            this.list_data[0].data = response.data.data;
            this.list_data[0].total = response.data.data.length;
          }
        })
        .catch((error) => {})
        .finally((response) => {
          this.list_data[selectedTab].loading = false;
        });
    },
    getFavoriteCasts: async function() {
      this.getApi(0);
    },
    getCastReviews: async function() {
      this.getApi(1);
    },
    getFollows: async function() {
      this.getApi(2);
    },
    getFollowers: async function() {
      this.getApi(3);
    },
  },
  computed: {
    currentItem() {
      return this.list_data[this.selectedTab];
    },
    // 320pxデバイスではv-tabsのフォントサイズ小さくする
    resposiveTabsFontSize() {
      if (this.$vuetify.breakpoint.width <= 320) {
        return {
          fontSize: '12px',
        };
      } else {
        return {};
      }
    },
    selectStyle() {
      if (this.$vuetify.breakpoint.width <= 355) {
        return {
          minWidth: '200px',
          height: '70px',
        };
      } else {
        return {
          width: '223px',
          height: '70px',
        };
      }
    },
    followOrFollowerTab() {
      return [2, 3].includes(this.selectedTab);
    },
    frequentlyPlayedGenreName() {
      if (!this.user.frequently_played_genre_name) return '';
      let results = [];
      const selected = this.businessTypes.filter((genre) => {
        return this.user.frequently_played_genre_name.includes(genre.id);
      });
      for (const genre of selected) {
        results.push(genre.name);
      }
      console.log(results.join('/'));
      return results.join('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  background-color: #ffffff;
  font-size: var(--read-font-size-s);
}
.nick__name {
  color: var(--read-font-color-sub);
  font-size: var(--read-font-size-m);
  font-weight: bold;
}
.user__info {
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.v-tab {
  width: 100%;
}
.select_box {
  font-size: var(--read-font-size-m);
  transform: scale(0.8) translate(-15px, 0);
}
.select_box ::v-deep .v-text-field__details {
  display: none;
}
.select_box ::v-deep .v-input__slot {
  background: #fff !important;
  margin-top: 5px !important;
}
.select_box ::v-deep .v-input__append-inner {
  margin-top: 3px !important;
}
.select_box ::v-deep .v-select__selection {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: var(--read-font-color-primary) !important;
  padding-left: 8px !important;
}
.self_introduction {
  margin: 10px 20px;
  white-space: pre-line;
}
.__btn {
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  width: 140px;
  height: 2rem;
  padding: 0 10px;
  font-size: var(--read-font-size-m);
  font-weight: bold;
  color: var(--main-color);
  border-radius: 1.8rem;
  border: solid 1.5px var(--main-color);
}
table,
tbody,
tr,
td {
  border: none;
}
td:nth-child(2) {
  padding-left: 20px;
}
::v-deep .v-card {
  box-shadow: none !important;
}
.user-page-sort-tab ::v-deep .v-tab {
  font-size: 12px;
  color: #4b4b4b;
  padding: 0 10px !important;
  max-width: 150px !important;
}
.user-page-sort-tab ::v-deep .v-input--selection-controls__input {
  margin: 0 !important;
  width: 20px !important;
}
.user-page-sort-tab ::v-deep .v-input--is-disabled .v-label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.user-page-sort-tab ::v-deep .v-input {
  margin-top: 6px !important;
}
.user-page-sort-tab ::v-deep .v-label {
  font-size: 10px !important;
  font-weight: bold !important;
}
.user-page-sort-tab ::v-deep .v-icon {
  font-size: 18px !important;
}
.user-page-sort-tab
  ::v-deep
  .theme--light.v-input--selection-controls.v-input--is-disabled
  .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}

@media screen and (max-width: 390px) {
  .first-tag {
    width: 90px !important;
  }
}

.user-page-sort-tab ::v-deep .v-slide-group__prev, .user-page-sort-tab ::v-deep .v-slide-group__next {
  display: none !important;
}
</style>
