<template>
  <v-dialog v-model="this.$store.state.siteMemberDialog.openDialog" max-width="500" class="user-profile-dialog" fullscreen persistent no-click-animation transition="fade-transition">
    <v-card>
      <div class="mx-auto" style="max-width:500px;">
        <CommonHeader style="position:sticky;top:0;z-index:10;" />
        <UserProfile style="height:100%;padding-bottom:0px !important;margin-bottom:0px !important;background-color:var(--new-main-bg-color) !important;padding-top: 40px !important;" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
const moment = require("moment");
import CommonHeader from '~/components/layouts/CommonHeader.vue';
import UserProfile from '~/pages/user/_id.vue';
import _ from 'lodash'

export default {
  components: {
    CommonHeader,
    UserProfile,
  },
}
</script>
