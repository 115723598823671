<template>
  <div style="max-width: 1000px">
    <div class="scroll-container" v-if="windowWidth <= 480">
      <div
          class="pa-2 scroll-item new-cast-wrapper  cast-review-box"
          :class="'new-cast' + castReview.id"
          v-for="(castReview, index) of castReviews"
          :key="castReview.id"
          style="margin:5px 10px 5px 5px;width:375px;"
          :style="{'marginLeft': (index==0) ? '5px':'0'}"
        >
        <CastReview
          :castReview="castReview"
          :omittedTextLength="118"
          :commentBoxHeightFixed="true"
          @change-guestAlert="guestAlert=true"
        />
        </div>
        <SeeMoreBtn
        v-if="nextPage < 30"
        class="v-slide-group__content mx-3"
        :btnName="selectMoreName('btnMore')"
        :loading="loading"
        @getMoreData="$emit('getMoreData')"
      />
      <SeeMoreBtn
        v-else
        class="v-slide-group__content mx-3"
        :btnName="selectMoreName('btnAll')"
        :loading="loading"
        @getMoreData="$router.push(`/${$i18n.locale}/cast-review`)"
      />
    </div>
    <v-slide-group class="cast-review-slide-wrapper" v-else>
      <v-slide-item
        class="pa-2 cast-review-box"
        v-for="(castReview, index) of castReviews"
        :key="castReview.id"
        style="margin:5px 10px 5px 5px;width:375px;"
        :style="{'marginLeft': (index==0) ? '5px':'0'}"
      >
        <CastReview
          :castReview="castReview"
          :omittedTextLength="118"
          :commentBoxHeightFixed="true"
          @change-guestAlert="guestAlert=true"
        />
      </v-slide-item>
      <SeeMoreBtn
        v-if="nextPage < 30"
        class="v-slide-group__content mx-3"
        :btnName="selectMoreName('btnMore')"
        :loading="loading"
        @getMoreData="$emit('getMoreData')"
      />
      <SeeMoreBtn
        v-else
        class="v-slide-group__content mx-3"
        :btnName="selectMoreName('btnAll')"
        :loading="loading"
        @getMoreData="$router.push(`/${$i18n.locale}/cast-review`)"
      />
    </v-slide-group>
    <div class="mt-4 mb-5" @click="$router.push(`/${$i18n.locale}/cast-review`)">
      <UpdateBtn :msg="$t('text_U7bc')" />
    </div>
    <!-- ダイアログ -->
    <SiteMemberProfileDialog v-if="this.$store.state.siteMemberDialog.openDialog" />
    <v-dialog v-model="guestAlert" max-width="400" transition="dialog-bottom-transition" style="z-index: 9999">
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 primary--text text-center">
          ログイン/会員登録するとユーザプロフィールページの閲覧が可能です
        </div>
        <div class="px-3">
          <v-btn block small elevation="0" v-on:click="guestAlert=false" style="color: var(--main-color); font-weight: bold">
            閉じる
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import UpdateBtn from "~/components/pages/home/UpdateBtn.vue";
import CastReview from "~/components/pages/castReview/CastReview.vue";
import SiteMemberProfileDialog from "~/components/pages/dialog/SiteMemberProfileDialog.vue";

export default {
  components: {
    CastReview,
    UpdateBtn,
    SiteMemberProfileDialog
  },
  props: [
    'castReviews',
    'nextPage',
    'loading'
  ],
  data() {
    return {
      guestAlert: false,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    selectMoreName (selectBtnName) {
      if (this.$i18n.locale == 'zh-cn') {
        return selectBtnName == 'btnMore' ? 'btnMoreZhCn' : 'btnAllZhCn'
      } else if (this.$i18n.locale == 'zh-tw') {
        return selectBtnName == 'btnMore' ? 'btnMoreZhTw' : 'btnAllZhTn'
      } else if (this.$i18n.locale == 'ko') {
        return selectBtnName == 'btnMore' ? 'btnMoreKo' : 'btnAllKo'
      } else {
        return selectBtnName == 'btnMore' ? 'btnMoreEn' : 'btnAllEn'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cast-review-box {
  background: var(--sub-bg-color) !important;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .scroll-container {
    display: flex;
    overflow-x: scroll;
    padding: 20px;
    width: 100%;
  }

  .scroll-item {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Chrome, Safari and Opera */
  .scroll-container::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  .scroll-container {
    scrollbar-width: none;
  }

  /* Internet Explorer and Edge */
  .scroll-container {
    -ms-overflow-style: none;
  }
}
</style>
