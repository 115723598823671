<template>
  <div id="main">
    <!--退店済みキャストの場合 -->
    <div v-if="cast.site_public_flag == 0 || cast.site_member_site_public_flag == 0" >
      <div class="trim-image-to-circle" :style="responsiveStyle"/>
    </div>
    <!-- Tokyo Escort OTOME非公開店舗キャストの場合 -->
    <div v-else-if="cast.shop.inbound_site_public_flag == 0">
      <div class="trim-image-to-circle" @click="profileAttentionDialog=true" :style="responsiveStyle"/>
      <v-avatar class="fav_btn" size="42" @click="followAttentionDialog=true" >
        <v-icon color="primary" size="26" style="margin-left: 0px;">mdi-heart-outline</v-icon>
      </v-avatar>
    </div>
    <!-- 通常のキャストの場合 -->
    <div v-else>
      <div class="trim-image-to-circle" @click="toCastProfileDialog(cast)" :style="responsiveStyle"/>
      <v-avatar class="fav_btn" size="42" @click="follow()" >
        <v-icon v-if="!fav" color="primary" size="26" style="margin-left: 0px;">mdi-heart-outline</v-icon>
        <v-icon v-if="fav"  color="primary" size="26" style="margin-left: 0px;">mdi-heart</v-icon>
      </v-avatar>
    </div>

    <v-dialog v-model="profileAttentionDialog">
      <v-card class="pa-4" style="font-weight:bold;">
        Tokyo Escort OTOME公開店舗ではない為、プロフィールは表示できません。
        <div class="text-center">
          <v-btn v-on:click="profileAttentionDialog=false" class="mt-6" color="primary" style="font-weight:bold;">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="followAttentionDialog">
      <v-card class="pa-4" style="font-weight:bold;">
        Tokyo Escort OTOME公開店舗ではない為、お気に入りに追加できません。
        <div class="text-center">
          <v-btn v-on:click="followAttentionDialog=false" class="mt-6" color="primary" style="font-weight:bold;">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import profile_image from '~/assets/img/noimage_p.png';
import profile_ngImage from '~/assets/img/ngimage_p.png';
import profile_closedImage from '~/assets/img/noimage_closed_p.png';


export default {
  name: "CastAvater",
  props: ['cast', 'image'],
  data(){
    return {
      fav: this.cast.is_following,

      profile_image: profile_image,
      profile_ngImage: profile_ngImage,
      profile_closedImage: profile_closedImage,

      profileAttentionDialog: false,
      followAttentionDialog: false
    }
  },
  methods: {
    toCastProfile() {
      this.$router.push({ path:'/cast/'+this.cast.url_hash,  query :{evaluate: '1' }})
    },
    follow: function () {
      this.fav = !this.fav;
      // Ajax DB更新
      this.$axios.post('private/save_follow_cast', {
        'email': this.$store.state.auth.user.email,
        'castId': this.cast.id,
        'siteMemberId': this.$store.state.siteMemberData.id,
        'fav': this.fav,
      }, {
        headers: {
          Authorization: `${this.$auth.$storage.getUniversal('_token.auth0', true)}`
        }
      })
      .then (response => {
      })

    },
    defaultImage(thumbnailUrl, defaultImageName) {
      // 非公開ならnowprinting
      if(this.cast.site_public_flag == 0 || this.cast.site_member_site_public_flag == 0) return this.profile_closedImage;

      if(!thumbnailUrl){
        if(defaultImageName.search('ng') == -1){
          return this.profile_ngImage;
        }else{
          return this.profile_image;
        }
      }else{
        return thumbnailUrl;
      }
    },
  },
  computed:{
    responsiveStyle(){
      // 表示する画像を取得。
      let image = this.defaultImage(this.image, this.cast.shop_default_thumbnail_set.set_name);

      if(this.$vuetify.breakpoint.width <= 320){
        return {
          backgroundImage: 'url(' + image + ')',
          width:  "100px",
          height: "100px",
          border: "3px solid var(--main-color)",
        }
      } else {
        return {
          backgroundImage: 'url(' + image + ')',
          width:  "130px",
          height: "130px",
          border: "4px solid var(--main-color)",
        }
      }
    }
  }
};
</script>

<style scoped>
.trim-image-to-circle {
  margin-left: 10px;
  background-size:cover;
  background-position: center center;
  border-radius: 50% 50% 50% 50%;
  /* border: 5px solid var(--main-color); */
}

.fav_btn {
  background-color: white;
  border-radius: 50% 50% 50% 50%;
  border: 2px solid var(--main-color);

  position: relative;
  bottom: 35px;
  margin-bottom: -35px;
  left  : 5px;

  z-index: 1;
}
.cast-closed{
  background-color: #424242;
  color: white;
  border-radius: 3px;
  font-size: 4px;
  width: 165px;
}

</style>