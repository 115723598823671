<template>
  <div class="d-flex justify-normal align-center">
    <div class="help_full__btn" :style="btn_style" v-on:click="click()">
      <GoodMain v-if="!pushed" style="height:14px;width:14px;" />
      <GoodWhite v-if="pushed" style="height:14px;width:14px;" />
      <span style="margin-left:6px;">参考になった</span>
    </div>
    <span class="count" :style="counter_style">{{ count_ }}</span>
  </div>
</template>
<script>
import GoodMain from '@/assets/img/good-main.svg';
import GoodWhite from '@/assets/img/good-white.svg';

export default {
  name: "HelpfullButton",
  components:{
      GoodMain,
      GoodWhite
  },
  props: {
    size: Number,
    count: Number,
    castReviewId: Number,
    email: String,
    default: Boolean,
    // ボタン押下できるかどうか
    pushAvailable: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      pushed: false,
      count_: 0,
    };
  },
  created() {
    this.pushed = this.default;
    this.count_ = this.count;
  },
  watch: {
    default (newVal, old) {
      this.pushed = newVal;
    },
    count (newVal, old) {
      this.count_ = newVal;
    }
  },
  mounted(){
    this.count_ = this.count;
    // 押せないボタンなら
    if(this.pushAvailable == false) {
      this.pushed = true;
    }
  },
  computed: {
    counter_style: function () {
      let val = "";
      if (this.size) {
        val = {
          fontSize: this.size + "px",
        };
      }
      return val;
    },
    btn_style: function () {
      return {
        backgroundColor: this.pushed ? "var(--main-color)" : "#fff",
        color: !this.pushed ? "var(--main-color)" : "#fff",
        whiteSpace: "nowrap",
      };
    },
  },
  methods: {
    click() {
      if(this.pushAvailable == false) return;

      if (this.pushed == false) {
        this.count_++;
        this.pushed = true;
      } else {
        this.count_--;
        if (this.helpFullCount <= 0) {
          this.count_ = 0;
        }
        this.pushed = false;
      }
      // vuexのstoreに保存されているbindCast/newCastReviewsの参考になったを上書き
      this.updateIsHelpfullOfCastReviews(this.castReviewId, !this.default);
      // Ajax DB更新
      this.$axios.post('private/save_help_full_cast_review', {
        'castReviewId': this.castReviewId,
        'siteMemberId': this.$store.state.siteMemberData.id,
        'pushed': this.pushed,
      }, {
        headers: {
          Authorization: `${this.$auth.$storage.getUniversal('_token.auth0', true)}`
        }
      })
      .then (response => {
      })
    },
  },
};
</script>

<style scoped>
.help_full__btn {
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  height: 1.5rem;
  width: 110px;
  padding: 0 10px;
  font-size: .7rem;
  font-weight:bold;
  color:var(--main-color);
  border-radius: 1.8rem;
  border: solid 1.5px var(--main-color);
}
.count {
  margin-left: 10px;
  margin-right: 10px;
  font-size: var(--read-font-size-m);
  color:var(--main-color);
  font-weight: bold;
}
</style>
