import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background":"#F0F3F4"}},[_c('div',{staticClass:"cast-shop-name mx-3 pt-3 mt-2 mb-3 d-flex align-center"},[_c('div',{staticClass:"triangle mr-1"}),_vm._v("\n    "+_vm._s(_vm.castReviewData.cast.shop.name + _vm.castReviewData.cast.site_cast_name)+"さんへの評価\n  ")]),_vm._v(" "),_c('div',{staticClass:"mx-3 pa-3 mb-3 review-comment-container"},[_c('div',{staticClass:"review-comment mb-3",staticStyle:{"white-space":"pre-line"},style:({
        'text-overflow': 'ellipsis',
        'border-radius': '0',
        'overflow': 'hidden',
        'display': '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': _vm.lineClamp,
      }),domProps:{"innerHTML":_vm._s(_vm.reviewOmittedText(_vm.unescapeHtml(_vm.comment), _vm.castReviewData.unfolding_flag))}}),_vm._v(" "),(!_vm.castReviewData.unfolding_flag)?_c('div',{staticClass:"text-center pb-3",staticStyle:{"color":"var(--main-color)","font-size":"var(--read-font-size-s)","font-weight":"bold"},on:{"click":function($event){_vm.castReviewData.unfolding_flag=true;_vm.lineClamp=''}}},[_vm._v("\n      続きを読む\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between align-center"},[(_vm.castReviewData.date)?_c('span',{staticClass:"review-date"},[_vm._v("\n        利用日："+_vm._s(_vm.$moment(_vm.castReviewData.date).format('YYYY年M月D日'))+"\n      ")]):_vm._e()])]),_vm._v(" "),_c(VRow,{staticClass:"mx-3 pa-0 ma-0 pb-3"},[_c(VCol,{staticClass:"pa-1 pl-0",attrs:{"cols":"5"}},[_c('CastImageProfile',{attrs:{"cast":_vm.castReviewData.cast,"castEnrolledFlag":_vm.getCastEnrolledFlag(_vm.castReviewData.cast),"executeParentMethod":true,"imageMaxHeight":240},on:{"toCastProfileDialog":function($event){return _vm.toCastProfile(_vm.castReviewData)}}})],1),_vm._v(" "),_c(VCol,{staticClass:"pa-1 pr-0",attrs:{"cols":"7"}},[_c('CastEvaluationTable',{staticClass:"mx-1 mb-3",attrs:{"overallSatisfaction":_vm.overallSatisfaction(_vm.castReviewData),"treatmentSatisfaction":_vm.treatmentSatisfaction(_vm.castReviewData)}}),_vm._v(" "),(_vm.getCastEnrolledFlag(_vm.castReviewData.cast))?_c('h2',{staticStyle:{"color":"#B22C27","font-size":"15px"}},[_c('CastSchedule',{attrs:{"castSchedules":_vm.castReviewData.cast.weeklyCastSchedules}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }