<template>
  <div id="tab" :style="style" @click="click()">
    <div class="count">{{ count }}</div>
    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "selected",
    event: "change-selected",
  },
  props:['selected', 'index', 'count', 'label'],

  computed:{
    style(){
      if(this.selected === this.index){
        return {
          'color': 'var(--main-color)',
          'borderBottom': '3px solid var(--main-color)',
        }
      } else {
        return {
          'color': '',
          'borderBottom': '',
        }
      }
    }
  },

  methods: {
    click() {
      this.$emit("change-selected", this.index);
    }
  },
};
</script>

<style scoped>
#tab {
  padding: 4px 0;
  width: 100%;
  text-align:center;
}
.count {
  /* font-family: 'New Gulim'; */
  font-size: 17px;
  font-weight: bold;
}
.label {
  font-weight: bold;
}
</style>

