import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"text-center"},[_c('div',{staticClass:"profile-container"},[_c(VCard,{staticClass:"profile-container__card",attrs:{"justify":"center"}},[_c('AvatarImage',{staticClass:"profile-image",attrs:{"width":"96","height":"96","imageUrl":_vm.imageSrc,"borderWidth":"4"}}),_vm._v(" "),_c('div',{staticClass:"profile-container__card-title text-center"},[_c('div',{staticClass:"nick-name text-center mb-3"},[_c('span',[_vm._v(_vm._s(_vm.nickName))])]),_vm._v(" "),_c('div',{staticClass:"self-introduction text-left mb-3"},[_c('span',[_vm._v(_vm._s(_vm.selfIntroduction))])]),_vm._v(" "),_c('div',{staticClass:"frequently-played-container"},[(_vm.country)?_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{staticClass:"ma-0 pa-0 d-flex align-center text-left",attrs:{"cols":"6"}},[_c(VIcon,{staticClass:"ma-1"},[_vm._v("mdi-earth")]),_vm._v(_vm._s(_vm.$t('nationality'))+"\n              ")],1),_vm._v(" "),_c(VCol,{staticClass:"ma-0 pa-0 d-flex align-center",attrs:{"cols":"6"}},[_vm._v("\n                "+_vm._s(_vm.country)+"\n              ")])],1):_vm._e()],1)]),_vm._v(" "),_c(VTabs,{staticClass:"tab-area",attrs:{"value":_vm.tabIndex},on:{"change":function($event){return _vm.changeTab($event)}}},[_c(VTab,{staticClass:"tab-label pb-2"},[_c('div',{staticClass:"count"},[_c('span',[_vm._v(_vm._s(_vm.favoriteCastCount))])]),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('favorite')))])]),_vm._v(" "),_c(VTab,{staticClass:"tab-label pb-2"},[_c('div',{staticClass:"count"},[_c('span',[_vm._v(_vm._s(_vm.reviewCount))])]),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('review')))])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }