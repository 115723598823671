<template>
  <div style="background: #F0F3F4;">
    <!-- キャスト・店舗名 -->
    <div class="cast-shop-name mx-3 pt-3 mt-2 mb-3 d-flex align-center">
      <div class="triangle mr-1"></div>
      {{ castReviewData.cast.shop.name + castReviewData.cast.site_cast_name }}さんへの評価
    </div>
    <!-- コメント、利用日、参考になった -->
    <div class="mx-3 pa-3 mb-3 review-comment-container">
      <div
        class="review-comment mb-3"
        style="white-space: pre-line;"
        :style="{
          'text-overflow': 'ellipsis',
          'border-radius': '0',
          'overflow': 'hidden',
          'display': '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': lineClamp,
        }"
        v-html="reviewOmittedText(unescapeHtml(comment), castReviewData.unfolding_flag)"
      ></div>
      <!-- 続きを読む -->
      <div
        v-if="!castReviewData.unfolding_flag"
        v-on:click="castReviewData.unfolding_flag=true;lineClamp=''"
        class="text-center pb-3"
        style="color:var(--main-color);font-size:var(--read-font-size-s);font-weight:bold;"
      >
        続きを読む
      </div>
      <div class="d-flex justify-space-between align-center">
        <span class="review-date" v-if="castReviewData.date">
          利用日：{{ $moment(castReviewData.date).format('YYYY年M月D日') }}
        </span>
        <!-- <HelpfullButton
          :count="castReviewData.help_full_count"
          :default="castReviewData.is_help_full"
          :cast-review-id="castReviewData.id"
          :site-member-id="castReviewData.site_member_id"
          :date="castReviewData.date"
          :disabled="!comment"
        /> -->
      </div>
    </div>
    <!-- キャストプロフィール・スケジュール・評価 -->
    <v-row class="mx-3 pa-0 ma-0 pb-3">
      <v-col cols="5" class="pa-1 pl-0">
        <CastImageProfile
          @toCastProfileDialog="toCastProfile(castReviewData)"
          :cast="castReviewData.cast"
          :castEnrolledFlag="getCastEnrolledFlag(castReviewData.cast)"
          :executeParentMethod="true"
          :imageMaxHeight="240"
        />
      </v-col>
      <v-col cols="7" class="pa-1 pr-0">
        <!-- キャスト評価 -->
        <CastEvaluationTable
          class="mx-1 mb-3"
          :overallSatisfaction="overallSatisfaction(castReviewData)"
          :treatmentSatisfaction="treatmentSatisfaction(castReviewData)"
        />
        <!-- キャストスケジュール -->
        <h2 v-if="getCastEnrolledFlag(castReviewData.cast)" style="color: #B22C27; font-size: 15px;">
          <CastSchedule :castSchedules="castReviewData.cast.weeklyCastSchedules" />
        </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import HelpfullButton from "~/components/pages/cast/HelpfullButton.vue";
import CastAvater from '~/components/pages/user/CastAvater.vue';
import CastImageProfile from "~/components/layouts/CastImageProfile.vue";
import CastEvaluationTable from "~/components/pages/mypage/CastEvaluationTable.vue";
import CastSchedule from "~/components/pages/castReview/CastSchedule.vue";

export default {
  name: "Review",
  props: ["param", "selectedTab"],
  components: {
    // HelpfullButton,
    CastAvater,
    CastImageProfile,
    CastEvaluationTable,
    CastSchedule
  },
  data() {
    return {
      castReviewData: this.param,
      lineClamp: 10,
    };
  },
  methods: {
    unescapeHtml: function(target) {
      if (typeof target !== 'string') return target;
      if (!target) return '-クチコミなし-';
      let patterns = {
          '&lt;'   : '<',
          '&gt;'   : '>',
          '&amp;'  : '&',
          '&quot;' : '"',
          '&#x27;' : '\'',
          '&#x60;' : '`'
      };
      return target.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function(match) {
          return patterns[match];
      });
    },
    toCastProfile: function(castEvaluation) {
      if (!this.getCastEnrolledFlag(castEvaluation.cast)) return;
      if (this.$store.state.siteMemberDialog.openDialog) {
        this.toCastProfileDialog(castEvaluation.cast);
      } else {
        this.$router.push(`/cast/${castEvaluation.cast.url_hash}`)
      }
    },
    overallSatisfaction(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.satisfaction)
        : Number(castEvaluation.overall_satisfaction);
    },
    treatmentSatisfaction(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? 0
        : Number(castEvaluation.treatment_satisfaction);
    },
    evaluationLooks(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.evaluation1)
        : Number(castEvaluation.evaluation_looks);
    },
    evaluationStyle(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.evaluation2)
        : Number(castEvaluation.evaluation_style);
    },
    evaluationPlay(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.evaluation3)
        : Number(castEvaluation.evaluation_play);
    },
    evaluationCharacter(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.evaluation4)
        : Number(castEvaluation.evaluation_character);
    },
    evaluationCaring(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.evaluation5)
        : Number(castEvaluation.evaluation_caring);
    },
    evaluation6(castEvaluation) {
      // 旧・新キャスト評価で分岐
      return castEvaluation.evaluation1
        ? Number(castEvaluation.evaluation6)
        : 0;
    },
  },
  computed: {
    comment() {
        let comment_element = document.createElement('div');
        comment_element.innerHTML = this.castReviewData.comment;

        // cast_review.commentにHTMLタグが含まれていて、
        // かつフォントサイズが数値ベタ書きの場合、Tokyo Escort OTOMEの標準フォントサイズに
        // 馴染まなくなるためフォントサイズ指定を削除
        comment_element.querySelectorAll('*').forEach(function(elem) {
            let font = elem.style.fontSize;
            if(font && font.match(/^(\d+)/) ){
                elem.style.fontSize = "";
            }
        });
        return comment_element.innerHTML
    },
    cup(){
      //カップ（1：A、2：B、3：C、4：D、5：E、6：F、7：G、8：H、9：I、10：J、11：K、12：L、13：M、14：N）
      //アスキーコードでカップサイズ取得
      return String.fromCharCode(64 + this.castReviewData.cast.cup );
    }
  },
};
</script>

<style lang="scss" scoped>
.cast-shop-name {
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--main-color);
}
.review-comment-container {
  background: #fff;
  border-radius: 15px;
}
.review-comment {
  white-space: pre-line;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--main-title-color);
}
.review-date {
  font-size: 0.625rem;
  font-weight: bold;
  color: var(--main-color);
}
.profile {
  color: var(--read-font-color-sub);
}
.site_member_name {
  font-weight: bold;
  margin-right: 3px;
}
.satisfaction {
  color: var(--read-font-color-sub);
  font-weight: bold;
}
.satisfaction .rating {
  color: var(--main-color);
}
.review_comment {
  margin: 10px 0;
  white-space: pre-line;
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.profile_icon {
  margin: 25px;
}
.review_body {
  width: 100%;
}
.none_review {
  margin-right: 10px;
  font-family: 'New Gulim';
  color: var(--main-color);
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.satisfaction ::v-deep .v-icon {
  padding: 0 0 2px 0 !important;
}
</style>