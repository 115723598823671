<template>
  <div>
    <v-card class="text-center">
      <div class="profile-container">
        <v-card class="profile-container__card" justify="center">
          <AvatarImage
            width="96"
            height="96"
            :imageUrl="imageSrc"
            borderWidth="4"
            class="profile-image"
          />
          <div class="profile-container__card-title text-center">
            <!-- ニックネーム -->
            <div class="nick-name text-center mb-3">
              <span>{{ nickName }}</span>
            </div>
            <!-- フォローボタン -->
            <!-- <div class="mb-3">
              <FollowBtn :site_member_id="siteMemberId" :is_follow="isFollow" />
            </div> -->
            <!-- 自己紹介 -->
            <div class="self-introduction text-left mb-3">
              <span>{{ selfIntroduction }}</span>
            </div>
            <!-- よく遊ぶエリアなど -->
            <div class="frequently-played-container">
              <v-row class="ma-0 pa-0" v-if="country">
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center text-left">
                  <v-icon class="ma-1">mdi-earth</v-icon>{{ $t('nationality') }}
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center">
                  {{ country }}
                </v-col>
              </v-row>
              <!-- <v-row class="ma-0 pa-0" v-if="frequentlyPlayedArea">
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center text-left">
                  <v-icon class="ma-1">mdi-map-marker</v-icon>{{ $t('frequently_played_area') }}
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center">
                  {{ frequentlyPlayedArea }}
                </v-col>
              </v-row> -->
              <!-- <v-row class="ma-0 pa-0" v-if="frequentlyPlayedGenreName">
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center text-left">
                  <IconGenre
                    style="fill:#4B4B4B;width:16px;height:16px;"
                    class="ma-2"
                  />{{ $t('frequently_played_genre') }}
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center">
                  {{ frequentlyPlayedGenreName }}
                </v-col>
              </v-row> -->
              <!-- <v-row class="ma-0 pa-0" v-if="frequentlyPlayedShopName">
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center">
                  <IconShop class="ma-2" />よく遊ぶお店
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 d-flex align-center">
                  {{ frequentlyPlayedShopName }}
                </v-col>
              </v-row> -->
            </div>
          </div>
          <!-- ゲストグレード・スター -->
          <!-- <div
            class="d-flex align-center pa-2 point-star pb-4"
            v-if="starPublicFlag && gradePublicFlag"
          >
            <div class="text-center point-star__col pa-1 point-star__col1">
              <div>
                <span class="d-inline mr-1 point-text">ゲストグレード</span>
              </div>
              <div class="point mt-1">
                <IconGrade :grade="grade" />
              </div>
            </div>
            <div class="text-center point-star__col point-star__col2">
              <div>
                <span class="star-text">スター</span>
              </div>
              <v-rating
                background-color="#E4C76A"
                color="#E4C76A"
                length="3"
                dense
                size="25"
                :value="Number(star ? star : 0)"
                half-increments
                readonly
                class="mt-1"
              />
            </div>
          </div> -->
          <v-tabs
            @change="changeTab($event)"
            :value="tabIndex"
            class="tab-area"
          >
            <v-tab class="tab-label pb-2">
              <div class="count">
                <span>{{ favoriteCastCount }}</span>
              </div>
              <div class="label">{{ $t('favorite') }}</div>
            </v-tab>
            <v-tab class="tab-label pb-2">
              <div class="count">
                <span>{{ reviewCount }}</span>
              </div>
              <div class="label">{{ $t('review') }}</div>
            </v-tab>
            <!-- <v-tab class="tab-label pb-2">
              <div class="count">
                <span>{{ followCount }}</span>
              </div>
              <div class="label">フォロー</div>
            </v-tab>
            <v-tab class="tab-label pb-2">
              <div class="count">
                <span>{{ followerCount }}</span>
              </div>
              <div class="label">フォロワー</div>
            </v-tab> -->
          </v-tabs>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import AvatarImage from '~/components/pages/mypage/AvatarImage.vue';
import IconGrade from '~/components/pages/common/IconGrade.vue';
import IconShop from '~/assets/img/icon-shop.svg';
import IconGenre from '~/assets/img/icon-genre.svg';
import Tab from '~/components/pages/user/Tab.vue';
import FollowBtn from '~/components/pages/user/FollowBtn.vue';

import '~/assets/css/userprofile.scss';

export default {
  components: {
    AvatarImage,
    IconGrade,
    IconShop,
    IconGenre,
    Tab,
    FollowBtn,
  },
  props: {
    siteMemberId: {
      type: Number,
    },
    isFollow: {
      type: [Number, Boolean],
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    imageSrc: {
      type: String,
    },
    nickName: {
      type: String,
    },
    selfIntroduction: {
      type: String,
    },
    country: {
      type: String,
    },
    frequentlyPlayedArea: {
      type: String,
    },
    frequentlyPlayedGenreName: {
      type: String,
    },
    frequentlyPlayedShopName: {
      type: String,
    },
    grade: {
      type: Number,
    },
    star: {
      type: Number,
    },
    favoriteCastCount: {
      type: Number,
    },
    reviewCount: {
      type: Number,
    },
    followCount: {
      type: Number,
    },
    followerCount: {
      type: Number,
    },
    starPublicFlag: {
      type: [Number, Boolean],
      default: 0,
    },
    gradePublicFlag: {
      type: [Number, Boolean],
      default: 0,
    },
  },
  model: {
    event: 'change-selected',
  },
  methods: {
    changeTab(event) {
      this.$emit('change-selected', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.nick-name {
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--main-title-color);
}
.follow-btn {
  margin: auto;
  background: #171c61 !important;
  border-radius: 20px;
  font-size: 0.5rem;
  font-weight: bold;
  color: #fff;
}
.self-introduction {
  font-size: 0.875rem;
  color: var(--main-title-color);
  margin: auto;
  width: 80%;
}
.frequently-played-container {
  margin: auto;
  width: 85%;
  font-size: 0.68rem;
  color: #2f2f2f;
}
::v-deep .v-dialog {
  overflow-y: initial !important;
}
::v-deep .v-tabs-bar {
  height: 55px !important;
}
::v-deep .follow__btn {
  margin: auto;
}
.tab-area ::v-deep .v-tabs-slider {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 70%;
}
::v-deep .tab-area .v-slide-group__wrapper {
  border-radius: 0px 0px 20px 20px !important;
}
</style>
