import { render, staticRenderFns } from "./RecommendedCast.vue?vue&type=template&id=6139efb0&scoped=true&"
import script from "./RecommendedCast.vue?vue&type=script&lang=js&"
export * from "./RecommendedCast.vue?vue&type=script&lang=js&"
import style0 from "./RecommendedCast.vue?vue&type=style&index=0&id=6139efb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6139efb0",
  null
  
)

export default component.exports