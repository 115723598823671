<template>
  <div style="background: #F0F3F4;">
    <!-- キャストプロフィール・スケジュール・評価 -->
    <v-row class="mx-3 pa-0 ma-0 pb-3 pt-3 mt-2">
      <v-col cols="5" class="pa-1 pr-0">
        <CastImageProfile
          @toCastProfileDialog="toCastProfile(cast)"
          :cast="cast"
          :castEnrolledFlag="getCastEnrolledFlag(cast)"
          :executeParentMethod="true"
          :imageMaxHeight="240"
        />
      </v-col>
      <v-col cols="7" class="pa-1">
        <!-- キャストスケジュール -->
        <h2 v-if="getCastEnrolledFlag(cast)" style="color: #B22C27; font-size: 15px;">
          <CastSchedule :castSchedules="cast.castSchedules" />
        </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CastImageProfile from "~/components/layouts/CastImageProfile.vue";
import CastSchedule from "~/components/pages/castReview/CastSchedule.vue";

export default {
  name: "Review",
  props: ["param", "selectedTab"],
  data() {
    return {
      cast: '',
    }
  },
  components: {
    CastImageProfile,
    CastSchedule
  },
  created() {
    this.cast = this.param;
  },
  methods: {
    toCastProfile: function(cast) {
      if (!this.getCastEnrolledFlag(cast)) return;
      if (this.$store.state.siteMemberDialog.openDialog) {
        this.toCastProfileDialog(cast);
      } else {
        this.$router.push(`/cast/${cast.url_hash}`)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cast-shop-name {
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--main-color);
}
.review-comment-container {
  background: #fff;
  border-radius: 15px;
}
.review-comment {
  white-space: pre-line;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--main-title-color);
}
.review-date {
  font-size: 0.625rem;
  font-weight: bold;
  color: var(--main-color);
}
.profile {
  color: var(--read-font-color-sub);
}
.site_member_name {
  font-weight: bold;
  margin-right: 3px;
}
.satisfaction {
  color: var(--read-font-color-sub);
  font-weight: bold;
}
.satisfaction .rating {
  color: var(--main-color);
}
.review_comment {
  margin: 10px 0;
  white-space: pre-line;
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.profile_icon {
  margin: 25px;
}
.review_body {
  width: 100%;
}
.none_review {
  margin-right: 10px;
  font-family: 'New Gulim';
  color: var(--main-color);
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.satisfaction ::v-deep .v-icon {
  padding: 0 0 2px 0 !important;
}
</style>