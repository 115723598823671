<template>
  <div class="new-cast-container">
    <div class="pa-2">
      <h2 class="primary--text d-flex align-center ma-3">
        <IconNew class="mr-2" />
        <span
          style="font-size: 22px; font-weight: bold; color: var(--main-color)"
          >{{ $t('text_s3x9') }}</span
        >
        <div
          v-on:click="newCastMessageDialog = !newCastMessageDialog"
          class="mx-1"
        >
          <IconQuestion style="height: auto" />
        </div>
      </h2>
      <!-- <div>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" sm="4" class="ma-0 py-0">
            <div class="pa-1 select-box-label">{{ $t('genre') }}</div>
            <div v-on:click="isGuestDialog($t('login_text_1'))">
              <v-select
                v-model="newCastGenre"
                item-text="label"
                item-value="value"
                :items="genreItems"
                hide-details
                outlined
                return-object
                class="select-box mb-1"
                style="background-color: #fff"
                :readonly="disabledGuest"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="6" sm="4" class="ma-0 py-0">
            <div class="pa-1 select-box-label">{{ $t('area') }}</div>
            <div v-on:click="isGuestDialog($t('login_text_2'))">
              <v-select
                v-model="newCastArea"
                item-text="label"
                item-value="value"
                :items="areaItems"
                hide-details
                outlined
                return-object
                class="select-box mb-1"
                style="background-color: #fff"
                :readonly="disabledGuest"
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="text-left reason-items pt-4 pl-2 pb-0">
            <div v-on:click="isGuestDialog($t('login_text_3'))">
              <v-checkbox
                :label="$t('text_76yv')"
                v-model="newCastExperienceEntryOnly"
                hide-details
                style="margin-top: 0; padding: 0 2px"
                :readonly="disabledGuest"
              />
            </div>
          </v-col>
        </v-row>
      </div> -->
      <!-- <div class="mb-3" @click="updateNewCasts()">
        <UpdateBtn :msg="$t('text_0h5k')" />
      </div> -->
    </div>
    <div>
      <div v-if="newCastLoading === true">
        <loading-image />
      </div>
      <div
        v-else-if="
          newCastLoading === false &&
          this.$store.state.bindCast.newCasts.length < 1
        "
        class="text-center"
        style="font-size: var(--read-font-size-s); font-weight: bold"
      >
        <span>{{ $t('no_match_found') }}</span>
      </div>
      <div class="scroll-container new-cast-slider-wrapper" v-else>
        <div
          class="scroll-item new-cast-wrapper"
          :class="'new-cast' + cast.id"
          v-for="cast in this.$store.state.bindCast.newCasts"
          :key="cast.id"
          style="margin: 5px 10px 5px 5px"
        >
          <div class="new-cast-wrapper" :class="'new-cast' + cast.id">
            <CastImageCard
              @toCastProfileDialog="toCastProfile(cast)"
              :cast="cast"
              :imageMaxHeight="240"
              :executeParentMethod="true"
            />
          </div>
        </div>
        <div
          v-if="nextPageNewFaces"
          class="v-slide-group__content see_more"
          @click="getNewCastData(true)"
        >
          <btnMore />
        </div>
        <p
          v-else-if="newFacePaginatorIsLast && isLastCastTodayEntry"
          class="v-slide-group__content last_card"
        >
          <btnEnd2 />
        </p>
        <p
          v-else-if="newFacePaginatorIsLast"
          class="v-slide-group__content last_card"
        >
          <btnEnd2 />
        </p>
      </div>
    </div>
    <v-dialog
      v-model="newCastMessageDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 primary--text text-left">
          {{ $t('text_08iy') }}
        </div>
        <div class="pa-3 text-left">
          {{ $t('text_lnb7') }}<br />
          <br />
          {{ $t('text_o97e') }}<br />
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="newCastMessageDialog = !newCastMessageDialog"
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>

<script>
import CastImageCard from '~/components/organisms/castImageCard/CastImageCard.vue';
import IconBeginner from '~/assets/img/icon-beginner.svg';
import IconNew from '~/assets/img/icon-new.svg';
import UpdateBtn from '~/components/pages/home/UpdateBtn.vue';
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';
import btnMore from '~/assets/img/btn-more-en.svg';
import btnEnd2 from '~/assets/img/btn-end2.svg';

export default {
  components: {
    CastImageCard,
    IconBeginner,
    UpdateBtn,
    GuestAlertDialog,
    IconNew,
    btnMore,
    btnEnd2,
  },
  mounted() {
    this.newCastGenre = { value: 0, label: this.$t('all') };
    this.newCastArea = { value: 0, label: this.$t('all') };
    this.newCastExperienceEntryOnly =
      this.$store.state.bindCast.newCastExperienceEntryOnly;
    if (this.$store.state.auth.loggedIn === false) {
      this.disabledGuest = true;
    }
  },
  data: () => ({
    newCastMessageDialog: false,
    newCastLoading: false,
    newCastArea: {},
    newCastGenre: {},
    newCastExperienceEntryOnly: false,
    newFacePaginator: null,
    guestAlertMessage: '',
    disabledGuest: false,
  }),
  computed: {
    nextPageNewFaces() {
      if (!this.newFacePaginator || this.newFacePaginatorIsLast) {
        return null;
      }
      if (
        this.newFacePaginator.current_page < this.newFacePaginator.last_page
      ) {
        return this.newFacePaginator.current_page + 1;
      }
    },
    newFacePaginatorIsLast() {
      if (this.newFacePaginator == null) {
        return false;
      }
      return (
        this.newFacePaginator.current_page == this.newFacePaginator.last_page
      );
    },
    isLastCastTodayEntry() {
      return (
        this.$store.state.bindCast.newCasts.slice(-1)[0].debut_date ==
        this.$moment().format('YYYY-MM-DD')
      );
    },
    genreItems() {
      return [
        { value: 0, label: this.$t('all') },
        { value: 1, label: this.$t('business_types.1.name') },
        { value: 2, label: this.$t('business_types.2.name') },
        { value: 3, label: this.$t('business_types.3.name') },
      ];
    },
    areaItems() {
      return [
        { value: 0, label: this.$t('all') },
        { value: 13, label: this.$t('tokyo') },
        { value: 14, label: this.$t('kanagawa') },
        { value: 11, label: this.$t('saitama') },
        { value: 12, label: this.$t('chiba') },
      ];
    },
  },
  methods: {
    toCastProfile(cast) {
      const target = document.getElementsByClassName(
        'new-cast-slider-wrapper'
      )[0].childNodes[0];
      this.$store.dispatch('castDialog/sliderScrollX', target.scrollLeft);
      this.$store.dispatch(
        'castDialog/sliderScrollXTargetId',
        'new-cast-slider-wrapper'
      );
      this.toCastProfileDialog(cast);
    },
    getNewCastData: function (isPaginate) {
      if (!isPaginate) {
        this.newCastLoading = true;
        this.$store.dispatch('bindCast/saveNewCastsData', []);
        // フォロワーカウント
        this.$store.dispatch(
          'followerCountData/saveNewCastsFollowerCountData',
          []
        );
      }
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      const params = {
        area: 0,
        genre: 0,
        experienceEntryOnly:
          this.$store.state.bindCast.newCastExperienceEntryOnly,
        siteMemberId: this.$store.state.siteMemberData.id,
        page: isPaginate ? this.nextPageNewFaces : null,
      };
      this.$axios
        .post('public/new-casts', params, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((response) => {
          this.newFacePaginator = response.data;
          this.$store.dispatch(
            'bindCast/saveNewCastsData',
            this.$store.state.bindCast.newCasts.concat(response.data.data)
          );
          // フォロワーカウント整形
          const followerCount = this.pickFollowerCountData(response.data.data);
          // フォロワーカウント
          this.$store.dispatch(
            'followerCountData/saveNewCastsFollowerCountData',
            this.$store.state.followerCountData.newCasts.concat(followerCount)
          );
        })
        .finally(() => {
          this.newCastLoading = false;
        });
    },
    updateNewCasts() {
      if (this.$store.state.auth.loggedIn === false) {
        this.isGuestDialog(this.$t('login_text_3'));
        return;
      }
      this.newCastLoading = true;
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      const params = {
        genre: this.newCastGenre.value,
        area: this.newCastArea.value,
        experienceEntryOnly: this.newCastExperienceEntryOnly,
        siteMemberId: this.$store.state.siteMemberData.id,
      };
      this.$axios
        .post('public/new-casts', params, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((response) => {
          this.newFacePaginator = response.data;
          const newCasts = response.data.data;
          this.$store.dispatch('bindCast/saveNewCastsData', newCasts);
          this.$store.dispatch('bindCast/saveNewCastArea', this.newCastArea);
          this.$store.dispatch('bindCast/saveNewCastGenre', this.newCastGenre);
          this.$store.dispatch(
            'bindCast/saveNewCastExperienceEntryOnly',
            this.newCastExperienceEntryOnly
          );
          // フォロワーカウント整形
          const followerCount = this.pickFollowerCountData(newCasts);
          // フォロワーカウント
          this.$store.dispatch(
            'followerCountData/saveNewCastsFollowerCountData',
            followerCount
          );
          this.$store.dispatch(
            'ssr/timeToGetNewCast',
            this.$moment().format('YYYY-MM-DD HH:mm:ss')
          );
        })
        .finally(() => {
          this.newCastLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-cast-container {
  .new-cast-wrapper {
    width: 160px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
  }
  .select-box-label {
    font-size: 13px;
    font-weight: bold;
    color: var(--main-title-color);
  }
  .reason-items {
    ::v-deep .v-label {
      color: var(--main-title-color) !important;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .last_card {
    margin: auto 10px;
  }
  .see_more {
    cursor: pointer;
    margin: auto 10px;
  }
  ::v-deep .v-input__append-inner {
    margin: 0 !important;
    margin-top: 4px !important;
  }
  ::v-deep .v-input__control {
    height: 42px !important;
  }
  ::v-deep .v-select__selection--comma {
    color: var(--main-title-color) !important;
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .new-cast-wrapper {
    height: 300px;
    width: 163px;
  }
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  padding: 20px;
  width: 100%;
}

.scroll-item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Firefox */
.scroll-container {
  scrollbar-width: none;
}

/* Internet Explorer and Edge */
.scroll-container {
  -ms-overflow-style: none;
}
</style>
