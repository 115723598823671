<template>
  <div>
    <h2 style="color: #B22C27; font-size: 15px">
      <v-icon color="primary">mdi-clock-outline</v-icon> {{ $t('schedule') }}
    </h2>
    <div class="py-2 pl-1 pr-0 cast-schedules">
      <div v-for="(schedule, key) in castSchedules" :key="key">
        <span class="caption">{{
          $moment(schedule.date).format('MM/DD')
        }}</span>
        <v-chip
          color="primary"
          text-color="white"
          x-small
          label
          class="caption px-1"
          v-if="schedule.id && schedule.is_free && !schedule.reception_end_flag"
          >{{ $t('available') }}</v-chip
        >
        <v-chip
          color="#BDBDBD"
          text-color="white"
          x-small
          label
          class="caption px-1"
          v-else-if="
            schedule.id && (!schedule.is_free || schedule.reception_end_flag)
          "
          >{{ $t('full') }}</v-chip
        >
        <v-chip
          color="#BDBDBD"
          text-color="white"
          x-small
          label
          class="caption px-1"
          v-else
          >{{ $t('day_off') }}</v-chip
        >
        <span class="caption" v-if="schedule.id">
          {{ $moment(schedule.stime).format('HH:mm') }} ~
          {{ $moment(schedule.etime).format('HH:mm') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment');

export default {
  props: ['castSchedules'],
};
</script>

<style lang="scss" scoped>
.cast-schedules {
  box-sizing: border-box;
  height: 190px;
  border-radius: 0 0 10px 10px;
  .caption {
    font-family: "YumeotoFont" !important;
    font-size: 11px !important;
    @media screen and (max-width:480px) {
      font-size: 10px !important;
    }
    font-weight: bold;
    color: var(--main-title-color);
    width: 62px;
    margin: 0px 3px 0px 0px;
    ::v-deep .v-chip__content {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
