import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center cast-image-profile-container",staticStyle:{"border-radius":"10px","position":"relative"}},[(!_vm.castEnrolledFlag)?_c(VOverlay,{staticStyle:{"z-index":"1 !important"},attrs:{"opacity":"0.6","absolute":""}},[_c('div',{staticClass:"px-1",staticStyle:{"font-size":"0.875rem","font-weight":"bold"}},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('cast_left')))])])]):_vm._e(),_vm._v(" "),(_vm.cast)?_c(VImg,{staticStyle:{"border-radius":"10px 10px 0 0"},attrs:{"cover":"","height":_vm.imageHeight,"max-height":_vm.imageMaxHeight,"min-height":_vm.imageMinHeight,"src":_vm.defaultImage(
        _vm.cast.thumbnailUrl,
        _vm.cast.shop_default_thumbnail_set.set_name,
        _vm.castRankingPublicFlag,
        _vm.castEnrolledFlag
      )},on:{"click":function($event){return _vm.toCastProfile(_vm.cast)}}},[(_vm.castRankingPublicFlag)?_c(VRow,{staticClass:"new-cast-list__text ma-0 px-3 pb-2",attrs:{"align":"end"}},[_c('div',{staticStyle:{"width":"100%","line-height":"16px"}},[_vm._v("\n        "+_vm._s(_vm.cast.site_cast_name)+"    "+_vm._s(_vm.$t('age'))+"\n        "+_vm._s(_vm.cast.age)+"\n      ")]),_vm._v(" "),_c('div',{staticStyle:{"width":"100%","line-height":"16px"}},[_vm._v("\n        T"+_vm._s(_vm.cast.height)+"    "+_vm._s(_vm.cast.bust)+"("+_vm._s(_vm.cupList[_vm.cast.cup])+")-"+_vm._s(_vm.cast.waist)+"-"+_vm._s(_vm.cast.hip)+"\n      ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cast-image-profile-bottom cast-image-profile-bottom-radius",staticStyle:{"position":"relative"}},[(_vm.castRankingPublicFlag)?_c('div',[_c('div',{staticStyle:{"margin-top":"2px","color":"var(--read-font-color-primary)"}},[_c('div',{staticStyle:{"line-height":"12px"}},[_vm._v("\n          "+_vm._s(_vm.cast.shop.min_course_minute)+_vm._s(_vm.$t('min'))+"　¥"+_vm._s(_vm.cast.shop.min_course_money.toLocaleString())+"～\n        ")])]),_vm._v(" "),_c('div',{staticStyle:{"width":"100%","margin-top":"20px","margin-bottom":"3px","border-top":"1px solid rgba(0,0,0,0.1)","position":"absolute","left":"0px"}}),_vm._v(" "),_c('div',{staticClass:"like-box"},[_c('CastLikeButton',{attrs:{"castId":_vm.cast.id,"isFavorite":_vm.cast.is_following,"siteMemberFollowCount":_vm.cast.site_member_follow_casts_count}})],1)]):_c('div',{staticClass:"top-secret"},[_c('div',{staticClass:"top-secret__inner pt-1 pb-1"},[_vm._v("\n        "+_vm._s(_vm.$('top_secret'))+"\n      ")])])]),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("\n        "+_vm._s(_vm.$t('cannot_display_private'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }