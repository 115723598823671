<template>
  <div>
    <div class="mb-1">
      <v-chip
        color="primary"
        text-color="white"
        x-small
        label
        class="caption px-1"
        v-if="cast.is_reservable"
        >{{ $t('available') }}</v-chip
      >
      <v-chip
        color="#BDBDBD"
        text-color="white"
        x-small
        label
        class="caption px-1"
        v-else-if="!cast.is_reservable"
        >{{ $t('full') }}</v-chip
      >
    </div>
    <div
      class="d-flex align-center justify-center cast-image-profile-container"
      style="border-radius: 10px"
    >
      <span
        style="
          font-weight: bold;
          color: var(--main-color);
          font-size: var(--read-font-size-l);
        "
      >
        {{ cast.start_datetime | time_only }} 〜
        {{ cast.end_datetime | time_only }}
      </span>
      <v-img
        v-if="cast"
        cover
        :height="imageHeight"
        :max-height="imageMaxHeight"
        :min-height="imageMinHeight"
        :src="
          defaultImage(
            cast.thumbnail_url,
            cast.shop_default_thumbnail_set_name,
            castRankingPublicFlag
          )
        "
        v-on:click="toCastProfile(cast)"
      >
        <v-overlay v-if="!castEnrolledFlag" opacity="0.6" absolute>
          <div class="px-1" style="font-size: 0.875rem; font-weight: bold">
            <div class="text-center">{{ $t('cast_left') }}</div>
          </div>
        </v-overlay>
        <v-row
          align="end"
          class="new-cast-list__text ma-0 px-3 pb-2"
          v-if="castRankingPublicFlag"
        >
          <!-- <div
            v-if="
              countFromDebutDate(cast.debut_date) <= 90 ||
              cast.position_type === 0
            "
            class="mb-1"
            style="width: 100%"
          >
            <span
              style="
                font-size: var(--read-font-size-xs);
                padding: 0 3px;
                color: #000;
                background-color: #fff;
                border-radius: 3px;
              "
              >{{ $t('new') }}</span
            >&nbsp;&nbsp;&nbsp;
            <span style="font-size: var(--read-font-size-xs)">{{
              debutDate(cast.debut_date)
            }}</span>
          </div>
          <div
            class="d-flex aling-center"
            style="
              width: 100%;
              line-height: var(--read-font-size-m);
              letter-spacing: 0.5px;
            "
            v-if="cast.cast_schedules_count <= 10"
          >
            <IconBeginner class="mr-1" style="fill: #fff; width: 12px" />
            <span style="padding-top: 5px"
              >{{ $t('attendance_count') }}{{ cast.cast_schedules_count }}</span
            >
          </div> -->
          <div style="width: 100%; line-height: 16px">
            {{ cast.site_cast_name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('age') }}
            {{ cast.age }}
          </div>
          <div style="width: 100%; line-height: 16px">
            T{{ cast.height }}&nbsp;&nbsp;&nbsp;&nbsp;{{ cast.bust }}({{
              cupList[cast.cup]
            }})-{{ cast.waist }}-{{ cast.hip }}
          </div>
        </v-row>
      </v-img>
      <!-- <div
        class="cast-image-profile-bottom"
        :class="{ 'cast-image-profile-bottom-radius' : !cast.point_type }"
        style="position: relative"
      > -->
      <div
        class="cast-image-profile-bottom cast-image-profile-bottom-radius"
        style="position: relative"
      >
        <div v-if="castRankingPublicFlag">
          <div style="margin-top: 2px; color: var(--read-font-color-primary)">
            <!-- <div style="line-height: 12px">{{ cast.shop_name }}</div> -->
            <div style="line-height: 12px">
              {{ cast.min_course_minute }}{{ $t('min') }} &yen;{{
                cast.min_course_money.toLocaleString()
              }}～
            </div>
          </div>
          <!-- <div class="mt-2">
            <span
              style="padding: 1px 6px; color: #fff; border-radius: 3px"
              :style="{
                backgroundColor:
                  cast.business_type_id === 1
                    ? '#54d1f0'
                    : cast.business_type_id === 2
                    ? '#E4C76A'
                    : cast.business_type_id === 3
                    ? '#ff96b7'
                    : '#ccc',
              }"
            >
              {{ $t(`business_types.${cast.business_type_id}.name`) }}
            </span>
          </div> -->
          <div style="width: 100%; margin-top:20px; margin-bottom:3px; border-top: 1px solid rgba(0,0,0,0.1); position: absolute; left: 0px;"></div>
          <div class="like-box">
            <CastLikeButton2
              :castId="cast.cast_id"
              :isFavorite="
                this.$store.state.siteMemberData.followCastIds.includes(
                  cast.cast_id
                )
                  ? true
                  : false
              "
              :siteMemberFollowCount="cast.site_member_follow_casts_count"
            />
          </div>
        </div>
        <div v-else class="top-secret">
          <div class="top-secret__inner pt-1 pb-1">TOP SECRET</div>
        </div>
      </div>
      <!-- <CastPointCard
        v-if="cast.point_type"
        :pointType="cast.point_type"
        :point="cast.point"
        :castEnrolledFlag="castEnrolledFlag"
      /> -->
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>
            {{ $t('cannot_display_private') }}
          </v-card-title>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const moment = require('moment');
import CastLikeButton2 from '~/components/layouts/CastLikeButton2.vue';
// import CastPointCard from '~/components/layouts/CastPointCard.vue';
import IconBeginner from '~/assets/img/icon-beginner.svg';
import noimage_m from '~/assets/img/noimage_m.png';
import ngimage_m from '~/assets/img/ngimage_m.png';
import IconPoint from '~/assets/img/icon-point.svg';
import IconNewcomer from '~/assets/img/icon-newcomer.svg';
import LogoGlay from '~/assets/img/logo-glay.svg';

export default {
  data: () => ({
    noimage_m: noimage_m,
    ngimage_m: ngimage_m,
    dialog: false,
  }),
  components: {
    CastLikeButton2,
    // CastPointCard,
    IconBeginner,
    IconPoint,
    IconNewcomer,
    LogoGlay,
  },
  props: {
    cast: {
      type: Object,
      required: true,
    },
    castRankingPublicFlag: {
      type: [Boolean, Number],
      default: true,
    },
    executeParentMethod: {
      type: [Boolean, Number],
      default: false,
    },
    castEnrolledFlag: {
      type: [Boolean, Number],
      default: true,
    },
    imageHeight: {
      type: [Number, String],
      default: 'auto',
    },
    imageMinHeight: {
      type: [Number, String],
      default: 240,
    },
    imageMaxHeight: {
      type: [Number, String],
      default: 315,
    },
  },
  computed: {
    cupList: function () {
      return this.$store.state.call.cupList;
    },
  },
  mounted: function () {},
  methods: {
    toCastProfile(cast) {
      if (!this.castRankingPublicFlag) {
        this.dialog = true;
        return;
      }
      if (this.executeParentMethod) {
        this.$emit('toCastProfileDialog');
        return;
      }
      this.toCastProfileDialog(cast);
    },
    /**
     * デビュー日(入店日)からの日数
     */
    countFromDebutDate(debutDate) {
      if (!debutDate) {
        return 0;
      }
      // 本日の営業日を取得
      const today =
        moment().hour() < 6
          ? moment().subtract(1, 'days').format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD');
      const dayCount = moment(today).diff(debutDate, 'days');
      return dayCount > 0 ? dayCount : 0;
    },
    /**
     * 本日の営業日
     */
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .new-cast-list__text {
    font-size: var(--read-font-size-m) !important;
  }
}
.cast-image-profile-container {
  flex-direction: column;
  box-shadow: 0px 0px 3px 0 #00000029;
  max-width: 230px;
  ::v-deep .v-overlay--active {
    touch-action: auto !important;
  }
  .cast-image-profile-bottom-radius {
    border-radius: 0 0 10px 10px;
  }
  .cast-image-profile-bottom {
    padding: 6px 8px;
    font-size: var(--read-font-size-xs);
    font-weight: bold;
    background-color: #fff;
    width: 100%;
    position: relative;
    height: 90px;
    .like-box {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 8px;
    }
    .top-secret {
      position: absolute;
      width: 90%;
      border: 1px solid #333333;
      padding: 2px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-5deg);
      .top-secret__inner {
        text-align: center;
        background: #333333;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>
