<template>
  <div>
    <h2 class="d-flex align-center" style="padding:10px 20px;">
      <IconReview class="mr-2" style="fill: var(--main-color);" />
      <span style="font-size:22px;font-weight:bold;color:var(--main-color);">{{ $t('text_Ke4q') }}</span>
    </h2>
    <div v-if="castReviewLoading">
      <loading-image />
    </div>
    <cast-review-slider
      v-else-if="!castReviewLoading && castReviews.length"
      :nextPage="perPage"
      :loading="loading"
      :castReviews="this.$store.state.bindCast.newCastReviews"
      @getMoreData="getCastReviewData(true)" />
  </div>
</template>

<script>
import CastReviewSlider from "~/components/pages/home/CastReviewSlider.vue";
import IconReview from '~/assets/img/icon-review-big.svg';
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CastReviewSlider,
    IconReview,
  },
  data() {
    return  {
      castReviewLoading: false,
      newCastReviewPaginator: null,
      data: [],
      castReviews: [],
      perPage: 10,
      loading: false
    }
  },
  computed: {
    ...mapState('bindCast', [
      'castReviewPage'
    ]),
    loadingComplete() {
      return this.page > 3;
    },
    newCastReviewsFromVuex() {
      return this.$store.state.bindCast.newCastReviews;
    },
  },
  watch: {
    newCastReviewsFromVuex (newCastReviews, oldCastReviews) {
      this.castReviews = JSON.parse(JSON.stringify(newCastReviews));
    }
  },
  methods: {
    getCastReviewData: async function (isPaginate = false) {
      let language;
      if (this.$i18n.locale == 'zh-cn') {
        language = 'zh-CN';
      } else if (this.$i18n.locale == 'zh-tw') {
        language = 'zh-TW';
      } else if (this.$i18n.locale == 'ko') {
        language = 'ko';
      } else {
        language = this.$i18n.locale;
      }
      const email =
        this.$store.state.auth.loggedIn === true
          ? this.$store.state.auth.user.email
          : "";
      if (!isPaginate) {
        this.castReviewLoading = true;
        this.castReviews = [];
      } else {
        this.perPage = this.perPage + 10;
        this.loading = true
      }
      // フォロワーカウントクリア
      this.$store.dispatch("followerCountData/saveNewCastReviewsFollowerCountData", []);
      const params = {
        do_not_show_blank_comment: 1,
        email: email,
        site_member_id: this.$store.state.siteMemberData.id,
        perPage: this.perPage,
        loggedIn: this.$store.state.auth.loggedIn,
        language: language,
      };
      this.$axios.post('public/get-cast-review-with-cast-info-for-top', params, {
        headers: { Authorization: this.$auth.$storage.getUniversal("_token.auth0", true )},
      }).then((response) => {
        this.$store.dispatch("bindCast/commitNewCastReviews", response.data.data);
        this.$store.dispatch("siteMemberData/saveHelpFullReviewLimit", {
          helpFullReviewLimit: response.data.limit.helpFullReviewLimit,
          maxHelpFullReview: response.data.limit.maxHelpFullReview
        });
        // フォロワーカウント整形
        const followerCount = this.pickFollowerCountDataForCastReviews(response.data.data);
        // フォロワーカウント
        this.$store.dispatch(
          "followerCountData/saveNewCastReviewsFollowerCountData",
          followerCount
        );
      }).finally(() => {
        this.loading = false;
        this.castReviewLoading = false;
      })
    },
  },
  mounted() {
    this.castReviews = this.newCastReviewsFromVuex;
  }
}
</script>