import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-1"},[(_vm.cast.is_reservable)?_c(VChip,{staticClass:"caption px-1",attrs:{"color":"primary","text-color":"white","x-small":"","label":""}},[_vm._v(_vm._s(_vm.$t('available')))]):(!_vm.cast.is_reservable)?_c(VChip,{staticClass:"caption px-1",attrs:{"color":"#BDBDBD","text-color":"white","x-small":"","label":""}},[_vm._v(_vm._s(_vm.$t('full')))]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-center cast-image-profile-container",staticStyle:{"border-radius":"10px"}},[_c('span',{staticStyle:{"font-weight":"bold","color":"var(--main-color)","font-size":"var(--read-font-size-l)"}},[_vm._v("\n      "+_vm._s(_vm._f("time_only")(_vm.cast.start_datetime))+" 〜\n      "+_vm._s(_vm._f("time_only")(_vm.cast.end_datetime))+"\n    ")]),_vm._v(" "),(_vm.cast)?_c(VImg,{attrs:{"cover":"","height":_vm.imageHeight,"max-height":_vm.imageMaxHeight,"min-height":_vm.imageMinHeight,"src":_vm.defaultImage(
          _vm.cast.thumbnail_url,
          _vm.cast.shop_default_thumbnail_set_name,
          _vm.castRankingPublicFlag
        )},on:{"click":function($event){return _vm.toCastProfile(_vm.cast)}}},[(!_vm.castEnrolledFlag)?_c(VOverlay,{attrs:{"opacity":"0.6","absolute":""}},[_c('div',{staticClass:"px-1",staticStyle:{"font-size":"0.875rem","font-weight":"bold"}},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('cast_left')))])])]):_vm._e(),_vm._v(" "),(_vm.castRankingPublicFlag)?_c(VRow,{staticClass:"new-cast-list__text ma-0 px-3 pb-2",attrs:{"align":"end"}},[_c('div',{staticStyle:{"width":"100%","line-height":"16px"}},[_vm._v("\n          "+_vm._s(_vm.cast.site_cast_name)+"    "+_vm._s(_vm.$t('age'))+"\n          "+_vm._s(_vm.cast.age)+"\n        ")]),_vm._v(" "),_c('div',{staticStyle:{"width":"100%","line-height":"16px"}},[_vm._v("\n          T"+_vm._s(_vm.cast.height)+"    "+_vm._s(_vm.cast.bust)+"("+_vm._s(_vm.cupList[_vm.cast.cup])+")-"+_vm._s(_vm.cast.waist)+"-"+_vm._s(_vm.cast.hip)+"\n        ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cast-image-profile-bottom cast-image-profile-bottom-radius",staticStyle:{"position":"relative"}},[(_vm.castRankingPublicFlag)?_c('div',[_c('div',{staticStyle:{"margin-top":"2px","color":"var(--read-font-color-primary)"}},[_c('div',{staticStyle:{"line-height":"12px"}},[_vm._v("\n            "+_vm._s(_vm.cast.min_course_minute)+_vm._s(_vm.$t('min'))+" ¥"+_vm._s(_vm.cast.min_course_money.toLocaleString())+"～\n          ")])]),_vm._v(" "),_c('div',{staticStyle:{"width":"100%","margin-top":"20px","margin-bottom":"3px","border-top":"1px solid rgba(0,0,0,0.1)","position":"absolute","left":"0px"}}),_vm._v(" "),_c('div',{staticClass:"like-box"},[_c('CastLikeButton2',{attrs:{"castId":_vm.cast.cast_id,"isFavorite":this.$store.state.siteMemberData.followCastIds.includes(
                _vm.cast.cast_id
              )
                ? true
                : false,"siteMemberFollowCount":_vm.cast.site_member_follow_casts_count}})],1)]):_c('div',{staticClass:"top-secret"},[_c('div',{staticClass:"top-secret__inner pt-1 pb-1"},[_vm._v("TOP SECRET")])])]),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("\n          "+_vm._s(_vm.$t('cannot_display_private'))+"\n        ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }