<template>
  <div @click="toUserPage()" style="background: #F0F3F4;">
    <v-divider class="mx-4"></v-divider>
    <v-row class="pa-1 py-4 ma-0">
      <v-col class="pa-0 ma-0" cols="3" style="position: relative;">
        <AvatarImage class="member-avatar" :imageUrl="siteMemberImageUrl" :width="50" :height="50" />
      </v-col>
      <v-col class="pa-0 ma-0" cols="9">
        <MemberInfo 
          :siteMemberId="userData.id"
          :name="userData.nickname"
          :isFollowing="userData.is_following"
          :reviewCount="userData.review_count"
          :followCount="userData.follow_count"
          :followerCount="userData.follower_count" 
        />
      </v-col>
    </v-row>
    <v-divider v-if="lastChild" class="mx-4"></v-divider>
  </div>
</template>

<script>
import profileDefaultImage from '~/assets/img/profile.jpg';
import AvatarImage from '~/components/pages/mypage/AvatarImage.vue';
import MemberInfo from '~/components/pages/mypage/MemberInfo.vue';

export default {
  name: "Follower",
  props: ["param", "follwer", "lastChild"],
  components: {
    AvatarImage,
    MemberInfo,
  },
  data() {
    return {
      profileDefaultImage: profileDefaultImage,
      AWS_S3_URL: process.env.AWS_S3_URL,
      userData: this.param,
    };
  },
  computed: {
    siteMemberImageUrl() {
      return this.userData && this.userData.image_filename 
        ? `${process.env.AWS_S3_URL}site_members/${this.userData.id}/mypage/photo/${this.userData.image_filename}`
        : this.profileDefaultImage;
    },
  },
  methods: {
    toUserPage() {
      const id = this.userData.id
      // ダイアログとして表示されていた場合はユーザを変えて再オープンさせる
      if (this.$store.state.siteMemberDialog.openDialog) {
        this.$store.dispatch('siteMemberDialog/commitOpenDialog', false);
        this.$store.dispatch('siteMemberDialog/commitSiteMemberId', '');
        setTimeout(() => {
          this.$store.dispatch('siteMemberDialog/commitOpenDialog', true);
          this.$store.dispatch('siteMemberDialog/commitSiteMemberId', id);
        }, 200)
      } else {
        this.$router.push(`/user/${id}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.member-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>